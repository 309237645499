import i18n, {TFunction} from 'i18next'
import moment from 'moment'
import {initReactI18next} from 'react-i18next'
import {getUrlParameterValue} from '../common/utils'
import {getBrowserLocale, getBrowserLanguage} from '../common/utils'

import de from './de.json'
import en from './en.json'
import en_AU from './en_AU.json'
import en_BE from './en_BE.json'
import en_GB from './en_GB.json'
import en_NL from './en_NL.json'
import fr_BE from './fr_BE.json'
import nl from './nl.json'
import nl_BE from './nl_BE.json'
import pl from './pl.json'

const resources = {
  en: {
    translation: en
  },
  en_gb: {
    translation: en_GB
  },
  de: {
    translation: de
  },
  de_de: {
    translation: de
  },
  en_au: {
    translation: en_AU
  },
  fr_be: {
    translation: fr_BE
  },
  nl_be: {
    translation: nl_BE
  },
  en_be: {
    translation: en_BE
  },
  en_nl: {
    translation: en_NL
  },
  pl: {
    translation: pl
  },
  pl_pl: {
    translation: pl
  },
  nl: {
    translation: nl
  },
  nl_nl: {
    translation: nl
  }
}

const countryLanguages = {
  NL: {
    nl:'nl',
    default:'en_nl'
  },
  BE: {
    fr: 'fr_be',
    nl: 'nl_be',
    default: 'en_be'
  },
  AU: {
    en: 'en_AU',
    default: 'en_AU'
  },
  GB: {
    en: 'en_GB',
    default: 'en_GB'
  },
  PL: {
    pl: 'pl',
    default: 'en'
  },
  DE: {
    de: 'de',
    default: 'en'
  }
}

const normalizeLocale = (locale: string) => locale && locale.replace('-', '_')
const countryCode = getUrlParameterValue('countryCode')

export const Localization = (): Promise<TFunction> => {

  const browserLanguage = getBrowserLanguage()

  const language = (() => {
    const languagesForCountryCode = countryLanguages[countryCode];
    if(languagesForCountryCode) {
      return languagesForCountryCode[browserLanguage] || languagesForCountryCode.default
    }
    return normalizeLocale(getBrowserLocale())
  })()

  moment.locale(language)

  const params = {
    lng: language,
    debug: false,
    fallbackLng: {default:['en']},
    resources,
    initImmediate: false
  }

  return i18n.use(initReactI18next).init(params)
}
