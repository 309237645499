import {Box, CircularProgress, Fade, IconButton, makeStyles, TableRowProps} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {FieldError, FieldValues, UseFormMethods, SubmitHandler} from 'react-hook-form'
import {useHoverDirty} from 'react-use'

import {HCThemeType} from '../../HCTheme.types'

import {useEditTableProps} from './EditTable'

const useStyles = makeStyles<HCThemeType, {isHovering: boolean}>((theme) => ({
  container: {
    backgroundColor: (props) => (props.isHovering ? theme.palette.grey[100] : ''),
    display: 'grid',
    alignItems: 'center',
    '& > div': {
      padding: '8px'
    }
  },
  cell: {
    lineHeight: '1.5rem',
    fontSize: '12pt'
  }
}))

interface GeneratorProps {
  register: UseFormMethods['register']
  name: string
  readOnly: boolean
  error: FieldError
  defaultValue: unknown
  control: UseFormMethods['control']
}
export type ComponentGenerator = (args: GeneratorProps) => JSX.Element

export interface InputDetail {
  leftCells?: React.ReactNode[]
  generator: ComponentGenerator
  rightCells?: React.ReactNode[]
  defaultValue: unknown
}

type InputDetails = Record<string, InputDetail>

export type OnSubmit<T extends InputDetails> = (
  data: Record<keyof T, unknown>,
  setError: (field: keyof T, message: string) => void
) => Promise<void> | void

export enum Mode {
  resting = 'resting',
  editing = 'editing'
}

interface EditRowProps<T extends InputDetails>
  extends Omit<TableRowProps, 'ref' | 'className' | 'onError' | 'onSubmit'> {
  inputs: T
  index: number
  onSubmit: OnSubmit<T>
  initialMode?: Mode
  onDelete?: () => Promise<void>
  onError?: (error: Error) => Promise<void>
  onLoading?: (isLoading: boolean) => void
  pending?: boolean
  isLoading?: boolean
  id: string
}
type ModeDetails = Record<Mode, {icon: JSX.Element; action: () => Promise<void>; label: string}>

/* *
 * This component provides a convenient wrapper for an editable row of data.  It considers the row as one form and allows passing in a set of inputs.  When changes are saved or deleted a single callback function is fired. This is meant to simplify the handling of form data and editing logic.
 * @param inputs - a record of input names to a generator callback and a default value for the input. Any display-only items that should be to the left or right of the input can be put as left/right cells and can be any valid react node.  The generator provides a react-hook-forms register function, the name of the input, and whether or not it's readonly as well as a defaultvalue.  Since inputs can vary so much this callback provides the ability to set the ref and name etc. as needed.
 * @param onSubmit - returns a corresponding map of input names (the keys of the `inputs` parameter) to their new values.
 * @param onDelete - callback that fires when the delete button is pressed
 * @param onError - handles any exceptions that occur in the `onSubmit` or `onDelete` callbacks
 * @param onLoading - callback fired whenever the loading state changes, allows for custom UX of the loading state
 * @param ... also takes in any props that can be applied to an MUI TableRow
 * @constructor
 */
export const EditRow = <T extends InputDetails>({
  inputs,
  index,
  onSubmit,
  onDelete,
  onError,
  onLoading,
  initialMode,
  id,
  pending,
  isLoading = false,
  ...tableRowProps
}: EditRowProps<T>) => {
  const {
    methods,
    gridStyle,
    pendingRowState: {setIsPending}
  } = useEditTableProps()
  const [mode, setMode] = useState<Mode>(initialMode ?? Mode.resting)
  const defaultValues = Object.entries(inputs).reduce(
    (prev, [key, detail]) => ({...prev, [`${key}[${index}]`]: detail.defaultValue}),
    {}
  )
  const [prevValues, setPrevValues] = useState(defaultValues)
  const rowRef = useRef<HTMLTableRowElement | null>(null)
  const isHovering = useHoverDirty(rowRef)

  const tryCatch = (cb: () => Promise<void>) => async () => {
    try {
      onLoading?.(true)
      await cb()
    } catch (error) {
      await onError?.(error)
      methods.reset(prevValues)
    } finally {
      onLoading?.(false)
    }
  }
  useEffect(() => {
    setPrevValues(defaultValues)
    Object.entries(prevValues).forEach(([name, value]) => methods.setValue(name, value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onValidSubmit: SubmitHandler<FieldValues> = async (data) => {
    const singleRow = Object.keys(data).reduce(
      (prev, key) => ({...prev, [key]: data[key][index]}),
      {}
    )
    await onSubmit(singleRow as Record<keyof T, unknown>, (name, message) =>
      methods.setError(`${name}[${index}]`, {message, type: 'validate'})
    )
    setPrevValues(
      Object.keys(data).reduce(
        (prev, key) => ({...prev, [`${key}[${index}]`]: data[key][index]}),
        {}
      )
    )
    setMode(Mode.resting)
    setIsPending(false)
  }
  // console.debug(methods.getValues())

  const primaryActionDetails: ModeDetails = {
    [Mode.resting]: {
      icon: <EditIcon />,
      // eslint-disable-next-line @typescript-eslint/require-await
      action: async () => setMode(Mode.editing),
      label: 'edit'
    },
    [Mode.editing]: {
      icon: <SaveIcon />,
      action: async () => methods.handleSubmit(onValidSubmit)(),
      label: 'save'
    }
  }
  const secondaryActionDetails: ModeDetails = {
    [Mode.resting]: {
      icon: <DeleteIcon />,
      action: async () => {
        await onDelete?.()
      },
      label: 'delete'
    },
    [Mode.editing]: {
      icon: <ClearIcon />,
      // eslint-disable-next-line @typescript-eslint/require-await
      action: async () => {
        if (pending) {
          setIsPending(false)
        } else {
          methods.reset(prevValues)
          setMode(Mode.resting)
        }
      },
      label: 'clear'
    }
  }

  const shouldShowButtons = isHovering || mode === Mode.editing
  const classes = useStyles({isHovering: shouldShowButtons})

  const mapNodesToCells = useMemo(
    () => (nodes: React.ReactNode[] | undefined, inputIdx: number) =>
      nodes?.map(
        (node, i) =>
          (
            <Box key={`display-${inputIdx}-${i}`} className={classes.cell}>
              {node}
            </Box>
          ) ?? null
      ),
    [classes.cell]
  )
  const tableCells = useMemo(
    () =>
      Object.entries(inputs).map(
        ([name, {generator, defaultValue, leftCells, rightCells}], inputIdx) => (
          <React.Fragment key={inputIdx}>
            {mapNodesToCells(leftCells, inputIdx)}
            <Box key={`cell-${inputIdx}`} className={classes.cell}>
              {generator({
                name: `${name}[${index}]`,
                defaultValue,
                error: methods.formState.errors[name]?.[index],
                readOnly: mode === Mode.resting,
                ...methods
              })}
            </Box>
            {mapNodesToCells(rightCells, inputIdx)}
          </React.Fragment>
        )
      ),
    [inputs, mapNodesToCells, classes.cell, index, methods, mode]
  )
  return (
    <div key={id} style={gridStyle} ref={rowRef} className={classes.container} {...tableRowProps}>
      {tableCells}
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Fade in={shouldShowButtons && !isLoading}>
          <IconButton
            data-test-id={`${primaryActionDetails[mode].label}-button-${index}`}
            aria-label={primaryActionDetails[mode].label}
            onClick={tryCatch(primaryActionDetails[mode].action)}
          >
            {primaryActionDetails[mode].icon}
          </IconButton>
        </Fade>
        <Box p={0.5} />
        <Fade in={mode === Mode.resting && !onDelete ? false : shouldShowButtons && !isLoading}>
          <IconButton
            data-test-id={`${secondaryActionDetails[mode].label}-button-${index}`}
            aria-label={secondaryActionDetails[mode].label}
            onClick={tryCatch(secondaryActionDetails[mode].action)}
          >
            {secondaryActionDetails[mode].icon}
          </IconButton>
        </Fade>
        {isLoading && <CircularProgress />}
      </Box>
    </div>
  )
}
