import {FormControl} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: `0 ${theme.spacing(3)}px`,
    height: theme.spacing(5)
  }
}))
interface TitleFormControlProps {
  className: string
}

const TitleFormControl: React.FC<TitleFormControlProps> = ({
  className: classNameProp,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <FormControl
      className={classNames(classes.formControl, classNameProp)}
      variant="standard"
      {...otherProps}
    />
  )
}

export default TitleFormControl
