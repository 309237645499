import {ErrorObject} from 'ajv'
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'

export interface StringParams {
  [s: string]: string
}

//
// Token Handling
//

export interface PersistedToken {
  accessToken: string
  refreshToken: string
}

export interface AuthenticatorResponse {
  /**
   * Exact info of the user name entered by the user. Effectively a duplicate:
   * Not useful for us as the same data is stored in the token itself.
   */
  username?: string

  /**
   * CSRF protection hash. Randomly generated and verified when the user
   * is returning to the application. Mostly relevant for Web only as we
   * have to make sure that the user is really returning after being
   * redirected from us before accepting the incoming authorization values.
   */
  state?: string

  /**
   * We only support `Bearer` types. This value is never stored as we do
   * not need it for any of the follow-up requests.
   */
  token_type?: 'Bearer'

  /**
   * Incoming relative expires field in seconds. This is a duplicate for the
   * UTC `exp` field in the actual token. We do not use this value.
   */
  expires_in?: number

  /**
   * Incoming access token. Will be renamed to `accessToken` before storing it.
   */
  access_token?: string

  /**
   * Incoming refresh token. Will be renamed to `refreshToken` before storing it.
   */
  refresh_token?: string
}

export interface RefreshTokenRequest {
  refresh_token: string
  grant_type: 'refresh_token'
}

//
// Login Flow
//

export interface LoginFlowState {
  state: string
  search: string
}

export interface LoginStorage {
  setToken: (token: PersistedToken) => Promise<void>
  getToken: () => Promise<PersistedToken>
  resetToken: () => Promise<void>
  setFlow: (flow: LoginFlowState) => Promise<void>
  getFlow: () => Promise<LoginFlowState>
  resetFlow: () => Promise<void>
}

export interface AuthClientData {
  id: string
  secret: string
  name: string
}

export interface AuthRequestProvider {
  getScope: () => Promise<string>
  getClient: () => Promise<AuthClientData>
  getUrl: () => Promise<string>
}

export interface LoginFlow {
  logout: (redirect: boolean) => Promise<void>
  redirectToAuthenticator: (path?: string) => Promise<void>
  initFromAuthenticator: () => Promise<void>
}

export interface BackendConfig {
  AUTH_URL: string
  API_URL: string
  [name: string]: string
}

export interface BackendConfigList {
  [name: string]: BackendConfig
}

export interface BackendSelector {
  getSelectedBackend: () => BackendConfig
}

//
// API Client
//

export interface PublicClientConfig {
  backendSelector: BackendSelector

  // TODO
  getTokenRefreshRequestHeaders?: () => any

  // Callbacks
  onResponseError?: (error: AxiosError) => void
  onValidateJsonError?: (errors: ErrorObject[], response: AxiosResponse) => void
  onRequest?: (request: AxiosRequestConfig) => void
  onResponse?: (response: AxiosResponse) => void
}

export type ClientConfig = PublicClientConfig & {
  authRequestProvider: AuthRequestProvider
  loginStorage: LoginStorage
  loginFlow: LoginFlow
}

export type TokenClientConfig = PublicClientConfig & {
  token: string
}

export interface FetchUsersParameters {
  username?: string
  companyName?: string
  country?: string
  name?: string
  eMail?: string
  mobileNumber?: string
  createdBy?: string
  isTester?: boolean
  isActive?: boolean
  isInternal?: boolean
  marketId?: string
  roleType?: string
  hasRoles?: boolean
  neverLoggedIn?: boolean
  skip?: number
  limit?: number
}

interface Value<T> {
  type: 'value'
  value: T
}

interface Err<E> {
  type: 'error'
  error: {errorCode: number; message: string}
}

export type Result<T, E> = Value<T> | Err<E>

export enum Product {
  OnSite = 'OnSite',
  Insight = 'Insight',
  Hub = 'Hub',
  TruckMonitor = 'TruckMonitor',
  HConnect = 'HConnect',
  UserManagement = 'UserManagement',
  HCEM = 'HCEM',
  PREDICT = 'PREDICT'
}

export enum Channel {
  SMS = 'sms',
  EMAIL = 'email'
}

export type PermissionType =
  | 'None'
  | 'VIEW_ORDERS_DELIVERIES'
  | 'CHANGE_ORDERS'
  | 'VIEW_MATERIALTESTS'
  | 'VIEW_FINANCE'
  | 'CHANGE_DELIVERIES_APPROVALS'
  | 'VIEW_DELIVERIES_APPROVALS'
  | 'VIEW_DEMAND'
  | 'VIEW_INVOICES'
  | 'VIEW_ELECTRICITY_PRICE'
  | 'VIEW_MACHINE_PLAN'
  | 'CHANGE_MACHINE_PLAN'
  | 'CHANGE_ELECTRICITY_ORDER'
  | 'VIEW_PAYERS'
  | 'CLONE_USERS'
  | 'VIEW_SUBSCRIPTIONS'
  | 'CHANGE_INVOICES'
  | 'VIEW_MATERIALCERTIFICATES_SNIPPETS'
  | 'CHANGE_MATERIALCERTIFICATES_SNIPPETS'
  | 'DELETE_MATERIALCERTIFICATES_SNIPPETS'
  | 'VIEW_CONTENTS'
  | 'CHANGE_CONTENTS'
  | 'DELETE_CONTENTS'
  | 'VIEW_ROLE_REQUESTS'
  | 'VIEW_CREDIT'
  | 'VIEW_QOUTES'
  | 'CHANGE_QUOTES'
  | 'CHANGE_TESTS'
  | 'VIEW_ALL_DATA'
  | 'CHANGE_ALL_DATA'
  | 'VIEW_USERS'
  | 'INVITE_USERS'
  | 'DELETE_USERS'
  | 'CHANGE_USERS'
  | 'GRANT_USER_MANAGEMENT_RIGHTS'
  | 'GRANT_SUPERADMIN_RIGHTS'
  | 'VIEW_TESTS'
  | 'VIEW_CUSTOMERS'
  | 'VIEW_SITES'
  | 'VIEW_PROJECTS'
  | 'VIEW_DOCUMENTS'
  | 'VIEW_MATERIALCERTIFICATES'
  | 'ASSIGN_ROLES'
  | 'VIEW_PLANTS'
  | 'ACCESS_PRODUCT_HUB'
  | 'DELETE_GLOBAL_ADMIN'
  | 'VIEW_TRUCKS'
  | 'CHANGE_PERMISSIONS'
  | 'MANAGE_CONFIGURATION'
  | 'VIEW_CONFIGURATIONS'
  | 'CHANGE_CONFIGURATIONS'

export type RoleType =
  | 'ACCOUNTANT'
  | 'BUSINESS_OWNER'
  | 'CONTROLLER'
  | 'CUSTOMER_ADMIN'
  | 'DELIVERIES_APPROVER'
  | 'FINISHER'
  | 'PLANT_MANAGER'
  | 'QA_ENGINEER'
  | 'SUPERINTEDENT'
  | 'ANALYTICS'
  | 'CONTROL_ROOM_OPERATOR'
  | 'DISPATCHER'
  | 'ENERGY_MANAGER'
  | 'GLOBAL_ADMIN'
  | 'MANAGING_DIRECTOR'
  | 'PLANT_DIRECTOR'
  | 'PRODUCTION_SUPERVISOR'
  | 'QC_SUPERVISOR'
  | 'SALES_AGENT'
  | 'SUPER_ADMIN'
  | 'SUPPORT_AGENT'
  | 'ORDER_PLACER'
  | 'SHIFT_LEADER'
