import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { OrderLayout } from './layouts/OrderLayout'

const App: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/">
        <OrderLayout />
      </Route>
    </Switch>
  </Router>
)

export default App
