import React from 'react'
import { Box, Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import { MobilePrimaryButton } from '@hconnect/uikit'

import { recordAndOpen } from '../../common/analytics'
import type { Order } from '../../common/interfaces'
import { getOrderCardStatus } from '../../common/factory'
import { OrderCardCircle } from '../../components/OrderCardCircle'
import { OrderDetails } from '../../components/OrderDetails'
import { OrderShare } from '../../components/OrderShare'
import { OrderOverview } from '../../components/OrderOverview'
import { OrderTeaserVideo } from '../../components/OrderTeaserVideo'
import { getStoreLink } from '../../common/utils'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

const useStyle = makeStyles((theme) => ({
  '@global': {
    body: {
      'overflow-y': 'scroll',
      'margin': 0
    }
  },
  'orderCardHeader': {
    width: 268
  },
  'root': {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  'orderCardContainer': {
    paddingTop: 10,
    paddingBottom: 30,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  'card': {
    width: 300
  },
  'cardHeaderContainer': {
    paddingHorizontal: 15,
    flexDirection: 'row'
  },
  'orderOverview': {
    alignSelf: 'stretch'
  },
  'bottomContainer': {
    paddingHorizontal: 15,
    flexDirection: 'column'
  },
  'shareButtonContainer': {
    display: 'flex',
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  'orderTeaserVideo': {
    alignSelf: 'stretch'
  },
  'getOnSiteButton': {
    display: 'flex',
    marginTop: 32,
    flexDirection: 'row',
    justifyContent: 'center'
  }
}))

interface OrderCardPageProps {
  order: Order
}

export const OrderCardPage: React.FC<OrderCardPageProps> = ({ order }) => {
  const classes = useStyle()
  const { t } = useOnSiteTranslation()

  const {
    orderCardTitle,
    orderCardAddress,
    orderCardStyles,
    orderCardMix,
    orderCardQuantityInformation,
    orderCardTruckInformationComponent,
    orderCardProgressInformationComponent,
    orderCardCompletedWithProgressInformation,
    orderCardRMCTestBooked
  } = getOrderCardStatus(order, t)
  const getOnsiteButtonText = t('common.getOnSiteNow').toUpperCase()
  return (
    <Box className={classes.root}>
      <Box className={classes.orderCardContainer}>
        <Box className={classes.orderCardHeader}>
          <OrderDetails order={order} />
        </Box>
        <Card className={classes.card}>
          <CardContent>
            <Box className={classes.cardHeaderContainer}>
              <OrderCardCircle
                circleBackgroundColor={orderCardStyles.circleColor}
                labelColor={orderCardStyles.labelColor}
                labelText={orderCardTitle}
                dotSize={19}
                fontStyles={orderCardStyles.circleTextFontStyles}
              />
            </Box>
            <Box className={classes.bottomContainer}>
              <Typography style={orderCardStyles.orderSubtitle}>{orderCardAddress}</Typography>
              {orderCardMix}
              {orderCardQuantityInformation}
              {orderCardRMCTestBooked}
              {orderCardTruckInformationComponent}
              {orderCardProgressInformationComponent}
              {orderCardCompletedWithProgressInformation}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className={classes.shareButtonContainer}>
        <OrderShare order={order} />
      </Box>
      <Box className={classes.orderOverview}>
        <OrderOverview order={order} />
      </Box>

      <Box className={classes.orderTeaserVideo}>
        <OrderTeaserVideo order={order} />
      </Box>

      <Box className={classes.getOnSiteButton}>
        <MobilePrimaryButton
          caption={getOnsiteButtonText}
          onClick={() => {
            recordAndOpen(
              'previewOnGetOnSiteNowLinkClicked',
              getStoreLink(window.navigator.userAgent),
              order
            )
          }}
        />
      </Box>
    </Box>
  )
}
