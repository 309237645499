import {Branding} from '../Branding.types'

import LogoHanson from './icons/Logo-Hanson-09-07-58-046.svg'

const brand: Branding = {
  nationalBrandName: 'Hanson',
  nationalLogoUrl: LogoHanson,
  nationalLogoTitle: 'Hanson',

  contactEmail: 'support@localhost',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Hanson',

  country: 'AU'
}

export default brand
