import {IconButton, TextField, makeStyles, Box} from '@material-ui/core'
import {fade} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import React, {useState} from 'react'

import TitleMenuItem from '../Title/Components/TitleMenuItem'
import TitleSelect from '../Title/Components/TitleSelect'

const useStyle = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[50],
    // TODO: should be #e7e7e7 but is not part of theme, yet. gray-colors are calculated.
    //  maybe UX can change these to #e5e5e5 which would match "theme.palette.grey[100]"
    border: `1px solid ${theme.palette.grey[100]}`,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    // height: theme.spacing(7),
    '&:hover': {
      // TODO: missing hover colors
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '&>*': {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      '&:hover': {
        borderLeftColor: theme.palette.grey[200]
      }
    }
  },
  error: {
    borderColor: theme.palette.error.main
  },
  textfield: {
    margin: 0,
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(),
      left: theme.spacing(2.5),
      color: fade(theme.palette.secondary.dark, 0.6),
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      transform: 'none'
    },
    '& .MuiInputBase-root': {
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(2.25),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      alignItems: 'flex-end',
      '& .MuiInputBase-input': {
        padding: 0
      }
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  icon: {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    }
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.dark,
    '&>svg': {
      color: theme.palette.secondary.dark
    },
    '&:after,&:before': {
      borderBottom: ['none', '!important']
    },
    '&:focus,&:active': {
      backgroundColor: ['transparent', '!important']
    }
  },
  searchIcon: {
    padding: 0
  },
  resetRoot: {
    display: 'inline-block'
  },
  reset: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  }
}))

interface Option {
  key: string
  value: string
}

interface LookupFilterProps {
  className?: string
  inputProps?: any
  selectProps?: any
  selectItems: Option[]
  selectedKey?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (arg: Option) => void
  onReset: () => void
  onSelectRenderValue: () => void
  onLookup: (e: React.FormEvent) => void
  placeholder?: string
  inputValue: string
  label?: string
  hasError?: boolean
  'data-test-id': string
}

export const LookupFilter: React.FC<LookupFilterProps> = ({
  className: classNameProp,
  selectProps,
  selectItems,
  selectedKey,
  placeholder,
  inputValue,
  label,
  onLookup,
  onInputChange,
  onSelectChange,
  onReset,
  onSelectRenderValue,
  onBlur,
  hasError,
  'data-test-id': dataTestId,
  ...otherProps
}) => {
  const classes = useStyle()
  const handleSelect = (item: Option) => {
    onSelectChange(item)
  }

  return (
    <Box
      className={classNames(classes.root, classNameProp, hasError && classes.error)}
      {...otherProps}
    >
      <TextField
        id="lookup-value"
        className={classes.textfield}
        label={label}
        value={inputValue}
        defaultValue={placeholder}
        margin="normal"
        // disabled={!selectedKey}
        onChange={onInputChange}
        onBlur={onBlur}
        onKeyPress={(e) => e.key === 'Enter' && onLookup(e)}
        data-test-id={dataTestId ? `${dataTestId}-input` : 'filter-input'}
        InputProps={{
          'aria-label': `${dataTestId} input field`,
          endAdornment: (
            <IconButton
              className={classNames(classes.icon, classes.searchIcon)}
              onClick={onLookup}
              aria-label={`${dataTestId} search button`}
              data-test-id={dataTestId ? `${dataTestId}-input-icon` : 'filter-input-icon'}
            >
              <SearchIcon fontSize="default" />
            </IconButton>
          )
        }}
      />
      <TitleSelect
        data-test-id={`${dataTestId || 'lookup'}-select`}
        className={classes.select}
        renderValue={onSelectRenderValue}
        value={selectedKey}
        aria-label="Lookup category selection dropdown menu"
        {...selectProps}
      >
        {selectItems &&
          selectItems.map((item) => {
            const dataTestValue =
              item.key.toLowerCase() === 'bol' ? 'ticket' : item.key.toLowerCase()
            return (
              <TitleMenuItem
                data-test-id={`${dataTestValue}-lookup-option`}
                key={item.key}
                value={item.key}
                aria-label={`Select option ${item.value}`}
                selected={item.key === selectedKey}
                onClick={() => handleSelect(item)}
              >
                {item.value}
              </TitleMenuItem>
            )
          })}
      </TitleSelect>
      <div className={classes.resetRoot}>
        <IconButton className={classNames(classes.icon, classes.reset)} onClick={onReset}>
          <CloseIcon />
        </IconButton>
      </div>
    </Box>
  )
}
