import { useTranslation } from 'react-i18next'

export const useOnSiteTranslation = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.language

  const strings = (string: string, params: { [key: string]: any } = {}): string => {
    const { count } = params

    // TODO: Use generic logic for plurals
    // const rule = i18n.services.pluralResolver.getRule(language)
    if (language === 'pl') {
      if (typeof count === 'number') {
        const context = (() => {
          if (count === 0) {
            return 'zero'
          }
          if (count === 1) {
            return '0'
          }
          if (count < 5) {
            return '1'
          }
          return '2'
        })()
        return t(string, { ...params, context })
      }
      return t(string, params)
    }
    if (typeof count === 'number' && count !== 1) {
      return t(string, { ...params, context: count !== 0 ? 'plural' : 'zero' })
    }
    return t(string, params)
  }

  return { t: strings, i18n }
}
