import React from 'react'

import { formatQuantity } from '../../common/utils'
import { OrderCardItem } from '../OrderCardItem'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

interface OrderCardQuantityInformationProps {
  materialQuantity: number
  materialQuantityUom: string
  businessLine: string
}

export const OrderCardQuantityInformation: React.FC<OrderCardQuantityInformationProps> = ({
  materialQuantity,
  materialQuantityUom,
  businessLine
}) => {
  const { t } = useOnSiteTranslation()
  const orderQuantityInformation = `${t('order.quantityAbbreviation')} ${formatQuantity(
    materialQuantity
  )} ${materialQuantityUom}`
  return (
    <OrderCardItem
      key="quantity-unit-status"
      information={orderQuantityInformation}
      iconName="invert_colors"
    />
  )
}

export default OrderCardQuantityInformation
