import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {SchemaIDs} from '../schemas'

const {RolesSchemaID} = SchemaIDs

export const getRolesByUserId = (api: AxiosInstance) => async (
  userId: string,
  params: any = {},
  axiosConfig?: AxiosRequestConfig
): Promise<any> => {
  const config = {params, schemaId: RolesSchemaID, ...axiosConfig}
  const response = await api.get(`/roles?userId=${userId}`, config)
  return response.data
}
