import React from 'react'
import { Text, View } from 'react-native-web'

interface OrderCardCircleProps {
  circleBackgroundColor: string
  labelColor: string
  labelText: string
  dotSize: number
  fontStyles: any
}

export const OrderCardCircle: React.FC<OrderCardCircleProps> = (props) => {
  const { dotSize, fontStyles, circleBackgroundColor, labelColor, labelText } = props

  return (
    <View
      style={{
        marginHorizontal: 5,
        marginVertical: 4,
        flexDirection: 'row',
        flex: 1
      }}
    >
      <View
        debug
        style={{
          width: dotSize,
          height: dotSize,
          borderRadius: dotSize / 2,
          backgroundColor: circleBackgroundColor,
          marginRight: 10,
          alignSelf: 'center'
        }}
      />
      <Text
        style={{
          color: labelColor || circleBackgroundColor,
          ...fontStyles,
          textAlign: 'left',
          flex: 1
        }}
      >
        {labelText}
      </Text>
    </View>
  )
}
