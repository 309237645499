import {Tab, Tabs, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  TabsRoot: {
    width: '100%',
    minWidth: '880px'
  },

  TabWrapper: {
    width: 'auto',
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
    ...theme.typography.h4
  },
  TabRoot: {
    minWidth: 'initial',
    padding: `0 ${theme.spacing(3)}px`,
    '& > *': {
      borderBottom: '4px solid transparent',
      marginBottom: -theme.spacing(),
      paddingBottom: theme.spacing(0.5),
      transition: 'border ease .2s'
    },
    '&:hover': {
      borderBottom: 0,
      '& > *': {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        marginBottom: -theme.spacing(),
        paddingBottom: theme.spacing(0.5)
      }
    }
  },
  TabSelected: {
    '& > *': {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      marginBottom: -theme.spacing()
    }
  },
  TabsIndicator: {
    display: 'none'
  }
}))

const LinkTab = (props: any) => (
  <Tab
    component={Link}
    disableRipple
    onClick={(event: React.ChangeEvent) => event.preventDefault()}
    {...props}
  />
)

interface Props {
  value: string
  onTabChange: (event: React.ChangeEvent<{}>, value: any) => void
  path: string
  animation?: boolean
  getRouteByPath(path: string): any
}

const NavLinks: React.FC<Props> = ({value, onTabChange, path, animation, getRouteByPath}) => {
  const parentRoute = getRouteByPath(path)
  const classes = useStyles()

  const activeValue =
    parentRoute &&
    Object.values(parentRoute.routes).find(
      // @ts-expect-error FIXME
      (route) => value === route.path || value.startsWith(`${route.path}/`)
    )

  const {t} = useTranslation()

  return (
    <Tabs
      classes={{
        root: classes.TabsRoot,
        indicator: classes.TabsIndicator
      }}
      indicatorColor="primary"
      textColor="primary"
      // @ts-expect-error FIXME: if activeValue is undefined, then no Tab should be active
      value={activeValue ? activeValue.path : false}
      onChange={onTabChange}
    >
      {parentRoute &&
        parentRoute.routes &&
        Object.keys(parentRoute.routes)
          .sort((a, b) => parentRoute.routes[a].order - parentRoute.routes[b].order)
          .filter((routeName) => parentRoute.routes[routeName].label)
          .map((routeName, i) => {
            const route = parentRoute.routes[routeName]
            const label = route.labelKey ? t(route.labelKey) : route.label
            const dataTestId = `navLink-${routeName.replace(/\W/g, '-').toLowerCase()}`
            return (
              <LinkTab
                key={route.path}
                className={classNames(classes.TabRoot)}
                classes={{
                  wrapper: classes.TabWrapper,
                  selected: classes.TabSelected
                }}
                to={route.path}
                value={route.path}
                label={label}
                data-test-id={dataTestId}
              />
            )
          })}
    </Tabs>
  )
}

export default NavLinks
