import {useState} from 'react'

const OREO_LOCAL_STORAGE_ACCEPTED_KEY = `HC-${process.env.REACT_APP_CLIENT_NAME}-oreo:accepted`

export interface LocaleStorageCookie {
  date?: number
  acceptedKeys: string[]
}
export const getAcceptedCookies = (): LocaleStorageCookie => {
  try {
    const localeStorageCookie = window.localStorage.getItem(OREO_LOCAL_STORAGE_ACCEPTED_KEY)
    if (localeStorageCookie) {
      const parsedCookie: LocaleStorageCookie = JSON.parse(localeStorageCookie)
      return Array.isArray(parsedCookie.acceptedKeys) ? parsedCookie : {acceptedKeys: []}
    }
  } catch (error) {
    console.error(error)
  }
  return {acceptedKeys: []}
}

export const useOreo = (
  initialValue: LocaleStorageCookie = {acceptedKeys: []}
): [LocaleStorageCookie, (value: LocaleStorageCookie) => void] => {
  const [accepted, setAccepted] = useState<LocaleStorageCookie>(() => {
    const localeStorageCookie = getAcceptedCookies()
    return localeStorageCookie?.acceptedKeys.length > 0 ? localeStorageCookie : initialValue
  })

  const setValueInLocalStorage = (value: LocaleStorageCookie): void => {
    try {
      setAccepted(value)
      window.localStorage.setItem(OREO_LOCAL_STORAGE_ACCEPTED_KEY, JSON.stringify(value))
    } catch (error) {
      console.error(error)
    }
  }
  return [accepted, setValueInLocalStorage]
}
