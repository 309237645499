import React from 'react'
import { StyleSheet, View } from 'react-native-web'
import { MobilePrimaryButton } from '@hconnect/uikit'

import type { Order } from '../../common/interfaces'
import { record } from '../../common/analytics'
import { Colors } from '../../common/constants'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    backgroundColor: Colors.White,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

interface OrderShareProps {
  order?: Order
}

interface ShareData {
  title: string
  text: string
  url: string
}

export const OrderShare: React.FC<OrderShareProps> = ({ order }) => {
  const { t } = useOnSiteTranslation()
  const sharingText = t('orderCard.sharingLink')

  if (!order || !order.firebaseLink) {
    return null
  }

  const { firebaseLink } = order

  const shareData = {
    title: t('orderShare.title'),
    text: t('orderShare.shareDescription'),
    url: firebaseLink
  }

  const shareOrCopyToClipboard = (shareData: ShareData): void => {
    if (order) {
      record('previewOnOrderShareLinkClicked', { firebaseLink }, order)
    }
    if (window.navigator && window.navigator.share) {
      window.navigator.share(shareData)
    } else if (window.navigator && window.navigator.clipboard) {
      navigator.clipboard.writeText(shareData.url).then(
        () => {
          alert(t('orderShare.copiedToClipboard'))
        },
        (err) => {
          alert(t('orderShare.commonError'))
        }
      )
    } else {
      alert(t('orderShare.commonError'))
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <MobilePrimaryButton
          caption={sharingText.toUpperCase()}
          onClick={() => shareOrCopyToClipboard(shareData)}
          showIcon
        />
      </View>
    </View>
  )
}
