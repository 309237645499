import {Toolbar} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  titleBar: {
    height: theme.spacing(8),
    backgroundColor: theme.palette.background.default,
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2)
  }
}))

const TitleBar: React.FC = ({children}) => {
  const classes = useStyles()
  return <Toolbar className={classes.titleBar}>{children}</Toolbar>
}

export default TitleBar
