import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import get from 'lodash/get'

import {getUrlParameterValue} from './utils'
import {getClientId} from './storage'
import {getEuMembers} from './constants'
import type {Order} from './interfaces'
import {getAcceptedCookies} from '@hconnect/uikit'

const instrumentationKey = get(process.env, 'REACT_APP_INSTRUMENTATION_KEY')
const appId = get(process.env, 'REACT_APP_AZURE_ID')

export const config = {
  appId,
  instrumentationKey,
  disableTelemetry: true,
  disableExceptionTracking: true,
  loggingLevelTelemetry: 0
}

export const appInsights = new ApplicationInsights({
  config
})

export const getApplicationInsights = (disableTelemetry: boolean) => {
  if (appInsights.config.disableTelemetry !== disableTelemetry) {
    appInsights.config.disableTelemetry = disableTelemetry
    appInsights.loadAppInsights()
  }
  return appInsights
}

export const disableTelemetry = (): void => {
  if (!appInsights.config.disableTelemetry) {
    appInsights.config.disableTelemetry = true
    appInsights.loadAppInsights()
  }
}

interface EventData {
  [key: string]: string
}

export const isCookieBannerNotRequired = (code: string): boolean =>
  !getEuMembers().includes(code.toUpperCase())

export const isTrackingAllowed = (): boolean => {
  if (isCookieBannerNotRequired(getUrlParameterValue('countryCode'))) {
    return true
  }
  const acceptedCookies = getAcceptedCookies()
  if (
    acceptedCookies.acceptedKeys.includes('necessary') &&
    acceptedCookies.acceptedKeys.includes('statistic')
  ) {
    return true
  }
  disableTelemetry()
  return false
}

export const record = (eventName: string, eventData: EventData, order?: Order): void => {
  const webviewType = getUrlParameterValue('webviewType')
  const environment = get(process.env, 'REACT_APP_STAGE')
  if (isTrackingAllowed() && getClientId()) {
    setTimeout(() =>
      getApplicationInsights(false).trackEvent({
        name: eventName,
        properties: {
          ...eventData,
          clientId: getClientId(),
          environment,
          roleRequestId: get(order, 'roleRequestId'),
          webviewType
        }
      })
    )
  }
}

export const recordAndOpen = (eventName: string, link: string, order?: Order): void => {
  const eventData = {
    link
  }
  record(eventName, eventData, order)
  const a = window.document.createElement('a')
  a.target = '_blank'
  a.href = link
  a.click()
}
