import {BackendConfig, BackendConfigList} from '../types'

const prod: BackendConfig = {
  AUTH_URL: 'https://auth.hconnect.digital',
  API_URL: 'https://api.hconnect.heidelbergcement.com'
}

const qa: BackendConfig = {
  AUTH_URL: 'https://auth-qa.hconnect.digital',
  API_URL: 'https://api-qa.hconnect.heidelbergcement.com'
}

const uat: BackendConfig = {
  AUTH_URL: 'https://auth-uat.hconnect.digital',
  API_URL: 'https://api.hconnect.heidelbergcement.com'
}

const regression: BackendConfig = {
  AUTH_URL: 'https://auth-regression.hconnect.digital',
  API_URL: 'https://api.hconnect.heidelbergcement.com'
}

const LOCAL_AUTH_ADDR = 'http://localhost:4000'

const prodLocal: BackendConfig = {
  ...prod,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=prod`
}

const qaLocal: BackendConfig = {
  ...qa,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=qa`
}

const uatLocal: BackendConfig = {
  ...uat,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=uat`
}

const regressionLocal: BackendConfig = {
  ...uat,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=regression`
}

const backends: BackendConfigList = {
  qa,
  uat,
  regression,
  prod,
  qaLocal,
  uatLocal,
  regressionLocal,
  prodLocal
}

export function getDefaultDSBackends(): BackendConfigList {
  return backends
}
