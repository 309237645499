import {IconButton, makeStyles} from '@material-ui/core'
import {AccountCircle} from '@material-ui/icons'
import React, {SyntheticEvent} from 'react'

interface AccountButtonProps {
  onClick: (event: SyntheticEvent<EventTarget>) => void
}

const useStyles = makeStyles((theme) => ({
  fill: {
    fill: theme.palette.background.default
  }
}))

const AccountButton: React.FC<AccountButtonProps> = ({onClick}) => {
  const classes = useStyles()

  return (
    <IconButton onClick={onClick} data-test-id="account-menu-icon">
      <AccountCircle fill={classes.fill} />
    </IconButton>
  )
}

export default AccountButton
