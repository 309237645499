import {RenderedItem, RenderedItemType} from './mapMdToRender.types'

import {ContentToken, HeadingToken, Token} from 'remarkable'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const {Remarkable} = require('remarkable')

const mapMdToRender = (content: string | null | undefined): RenderedItem[] => {
  const md = new Remarkable()
  const parsedData = md.parse(content, {})
  const result: RenderedItem[] = []

  const headers: {[key: number]: RenderedItemType} = {
    1: RenderedItemType.HeaderOne,
    2: RenderedItemType.HeaderTwo,
    3: RenderedItemType.HeaderThree,
    4: RenderedItemType.HeaderFour,
    5: RenderedItemType.HeaderFive,
    6: RenderedItemType.HeaderSix
  }

  const parseContent = (content: string) => {
    const reLink = /\[([^[]+)]\(([^)]+)\)/g
    const reStrong = /\*\*(.+?)\*\*/gs
    const reEm = /\*([^*]+?)(?!\*\*)\*/g

    return content
      .replace(reLink, '<a href="$2">$1</a>')
      .replace(reStrong, '<strong>$1</strong>')
      .replace(reEm, '<em>$1</em>')
  }

  let isList = false

  parsedData.forEach((parsedItem: Token) => {
    const parsedItemType = parsedItem.type

    if (parsedItemType === 'heading_open') {
      result[result.length] = {
        type: headers[(parsedItem as HeadingToken).hLevel],
        content: []
      }
    }

    if (parsedItemType === 'paragraph_open' && !isList) {
      result[result.length] = {
        type: RenderedItemType.Paragraph,
        content: []
      }
    }

    if (parsedItemType === 'code' || parsedItemType === 'fence') {
      result[result.length] = {
        type: RenderedItemType.CodeBlock,
        content: [parseContent((parsedItem as ContentToken).content)]
      }
    }

    if (parsedItemType === 'ordered_list_open') {
      isList = true
      result[result.length] = {
        type: RenderedItemType.OrderedList,
        content: []
      }
    }

    if (parsedItemType === 'bullet_list_open') {
      isList = true
      result[result.length] = {
        type: RenderedItemType.UnorderedList,
        content: []
      }
    }

    if (parsedItemType === 'ordered_list_close' || parsedItemType === 'bullet_list_close') {
      isList = false
    }

    if (parsedItemType === 'inline' && !isList) {
      result[result.length - 1].content.push(parseContent((parsedItem as ContentToken).content))
    }

    if (parsedItemType === 'inline' && isList) {
      result[result.length - 1].content.push(parseContent((parsedItem as ContentToken).content))
    }
  })

  return result
}

export {mapMdToRender}
