import {Branding} from '../Branding.types'

import LogoLehighHanson from './icons/Logo-Lehigh-Hanson-09-07-58-089.svg'

const brand: Branding = {
  nationalBrandName: 'Lehigh Hanson',
  nationalLogoUrl: LogoLehighHanson,
  nationalLogoTitle: 'Lehigh Hanson',
  nationalUrl: 'https://www.lehighhanson.com/',
  contactEmail: 'customerportal@lehighhanson.com',
  contactEmailSubject: 'Mail from Hub Portal',
  hconnectLinkUrl: '//hub.hconnect.digital/nam/',
  safetyDataSheetLink: 'https://www.lehighhanson.com/resources/safety-data-sheets',

  pageTitle: 'Lehigh Hanson',

  country: 'US'
}

export default brand
