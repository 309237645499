import moment, {LongDateFormatKey} from 'moment'

const momentShort = (locale: string, momentFormat: LongDateFormatKey) =>
  moment
    .localeData(locale)
    .longDateFormat(momentFormat)
    // eslint-disable-next-line no-useless-escape
    .replace(/[,./\\]*\s*Y+\s*[,./\\]?/, '')

const momentLong = (locale: string, momentFormat: LongDateFormatKey) =>
  moment.localeData(locale).longDateFormat(momentFormat)

const checkYear = (_arr: moment.Moment[]) => _arr[0].year() === _arr[1].year() /* ?*/

export function rangeDate(
  _arr: moment.Moment[],
  locale: string,
  momentFormat: LongDateFormatKey
): string {
  const format = checkYear(_arr)
    ? momentShort(locale, momentFormat)
    : momentLong(locale, momentFormat)
  return `${_arr[0].format(format)}–${_arr[1].format(momentLong(locale, momentFormat))}`
}
