import {LoginFlowState, LoginStorage, PersistedToken} from '../types'

const defaultStorageKey = `HC-${process.env.REACT_APP_CLIENT_NAME}-${process.env.REACT_APP_STAGE}`

function makeTokenReadable(accessToken?: string) {
  if (accessToken) {
    return `...${accessToken.slice(-10)}`
  }

  return 'null'
}

export class BrowserLoginStorage implements LoginStorage {
  constructor(storageKey: string = defaultStorageKey) {
    this.storageKey = storageKey
  }

  storageKey: any = null

  getToken() {
    const tokenInfo = localStorage.getItem(this.storageKey)
    return tokenInfo ? JSON.parse(tokenInfo) : {}
  }

  async setToken(token: PersistedToken): Promise<void> {
    if (!token.accessToken) {
      console.warn('Got invalid token info to store (missing field access_token)! Ignoring!')
      return
    }

    const oldToken = await this.getToken()

    if (oldToken.accessToken === token.accessToken) {
      console.log('Identity: Got identical access token from identity server!')
    } else {
      console.log(
        `Identity: Got updated access token: ${makeTokenReadable(
          oldToken.accessToken
        )} => ${makeTokenReadable(token.accessToken)}`
      )
      localStorage.setItem(this.storageKey, JSON.stringify(token))
    }
  }

  async resetToken(): Promise<void> {
    localStorage.removeItem(this.storageKey)
  }

  async setFlow(flowData: LoginFlowState): Promise<void> {
    localStorage.setItem(`${this.storageKey}-loginFlow`, JSON.stringify(flowData))
  }

  async getFlow(): Promise<LoginFlowState> {
    const flowData = localStorage.getItem(`${this.storageKey}-loginFlow`)
    return flowData ? JSON.parse(flowData) : {}
  }

  async resetFlow(): Promise<void> {
    // Remove our own fields which are just use temporary during
    // exchange with the authentication system.
    localStorage.removeItem(`${this.storageKey}-loginFlow`)
  }

  async clear(): Promise<void> {
    // Remove our own fields which are just use temporary during
    // exchange with the authentication system.
    sessionStorage.clear()
  }
}
