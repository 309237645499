import React from 'react'

import { OrderCardItem } from '../OrderCardItem'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

export const OrderCardRMCTestBooked = () => {
  const { t } = useOnSiteTranslation()
  return (
    <OrderCardItem key="mix" information={t('order.rmc.isTestBooked')} iconName="bookmark-border" />
  )
}
