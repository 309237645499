import {ResizeObserver} from '@juggle/resize-observer'

// Injecting ResizeObserver Polyfill
// https://caniuse.com/#feat=resizeobserver
// Required for Safari, IE11, Edge, ...
// @ts-expect-error FIXME
if (!window.ResizeObserver) {
  // @ts-expect-error FIXME
  window.ResizeObserver = ResizeObserver
}

export * from './Assets'
export * from './Molecules'
export * from './HCTheme'
export * from './HCTheme.types'
export * from './Localization'
export * from './Tools'
export * from './Router'
export * from './constants'
export * from './Shell'
export * from './Branding'
export * from './Fonts'
export * from './Oreo'
