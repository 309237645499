import React from 'react'
import { StyleSheet, View } from 'react-native-web'

import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'
import type { Order } from '../../common/interfaces'
import {
  OrderProgressBar,
  getBackgroundColor,
  getInTransitColor,
  getOnSiteColor,
  getPouredColor
} from '../OrderProgressBar'
import { formatQuantity, getStatusQuantities, getTotalQuantity } from '../../common/utils'
import { OrderCardCircle } from '../OrderCardCircle'
import { Colors, Fonts } from '../../common/constants'

const styles = StyleSheet.create({
  progressBarContainer: {
    marginTop: 6
  },
  progressBarCard: {
    paddingTop: 16,
    paddingHorizontal: 8,
    marginBottom: 32
  },
  borderTop: {
    borderTopColor: Colors.Gray,
    borderTopWidth: 1
  }
})

interface OrderCardProgressInformationProps {
  order: Order
  isCompleted: boolean
}

export const OrderCardProgressInformation: React.FC<OrderCardProgressInformationProps> = ({
  order,
  isCompleted
}) => {
  const { t } = useOnSiteTranslation()

  const _renderProgressBarLabel = (circleProps) => <OrderCardCircle {...circleProps} />

  const { materialQuantityUom, deliveryProgress } = order
  const { arrivedDeliveries, deliveredDeliveries, shippedDeliveries } = deliveryProgress
  const numberOfTrucksOnSite = arrivedDeliveries - deliveredDeliveries
  const numberOfTrucksInTransit = shippedDeliveries - arrivedDeliveries

  const {
    pouredQuantity,
    onSiteQuantity,
    inTransitQuantity,
    remainingQuantity
  } = getStatusQuantities(order)
  const totalQuantity = getTotalQuantity(order)

  const formattedPouredQuantity = formatQuantity(pouredQuantity)
  const formattedTotalQuantity = formatQuantity(totalQuantity)
  const pouredQuantityText = t('HCProgressBar.pouredQuantity', {
    formattedPouredQuantity,
    orderQuantityUnit: materialQuantityUom,
    formattedTotalQuantity
  })

  const formattedOnSiteQuantity = formatQuantity(onSiteQuantity)
  const onSiteQuantityText = t('HCProgressBar.onSiteQuantity', {
    formattedOnSiteQuantity,
    orderQuantityUnit: materialQuantityUom,
    count: numberOfTrucksOnSite
  })

  const formattedInTransitQuantity = formatQuantity(inTransitQuantity)
  const inTransitQuantityText = t('HCProgressBar.inTransitAndLoadedQuantity', {
    formattedInTransitQuantity,
    orderQuantityUnit: materialQuantityUom,
    count: numberOfTrucksInTransit
  })

  const formattedRemainingQuantity = formatQuantity(remainingQuantity)
  const remainingQuantityText = t('HCProgressBar.remainingQuantity', {
    formattedRemainingQuantity,
    orderQuantityUnit: materialQuantityUom
  })

  const dotSize = 14
  const progressFontSize = 14
  const overallProgressFontSize = 16

  const pouredDotColor = getPouredColor(order)
  const onSiteDotColor = getOnSiteColor(order)
  const inTransitDotColor = getInTransitColor(order)
  const remainingDotColor = getBackgroundColor(order)

  return (
    <View style={[ styles.progressBarCard, isCompleted && styles.borderTop ]}>
      {_renderProgressBarLabel({
        circleBackgroundColor: pouredDotColor,
        labelColor: Colors.TealDark,
        labelText: pouredQuantityText,
        dotSize,
        fontStyles: { ...Fonts.RobotoRegular, fontSize: overallProgressFontSize }
      })}

      <OrderProgressBar
        order={order}
        showAllDetails
        progressBarHeight={20}
        containerStyle={styles.progressBarContainer}
      />

      {!isCompleted &&
        _renderProgressBarLabel({
          circleBackgroundColor: onSiteDotColor,
          labelColor: Colors.TealDark,
          labelText: onSiteQuantityText,
          dotSize,
          fontStyles: { ...Fonts.RobotoRegular, fontSize: progressFontSize }
        })}

      {!isCompleted &&
        _renderProgressBarLabel({
          circleBackgroundColor: inTransitDotColor,
          labelColor: Colors.TealDark,
          labelText: inTransitQuantityText,
          dotSize,
          fontStyles: { ...Fonts.RobotoRegular, fontSize: progressFontSize }
        })}

      {!isCompleted &&
        _renderProgressBarLabel({
          circleBackgroundColor: remainingDotColor,
          labelColor: Colors.TealDark,
          labelText: remainingQuantityText,
          dotSize,
          fontStyles: { ...Fonts.RobotoRegular, fontSize: progressFontSize }
        })}
    </View>
  )
}
