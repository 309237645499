import React from 'react'

import AppInfo from './AppInfo'

function readEnv(name: string) {
  // The variables used here are injected by the HTML file or
  // made available by process.env lookup
  // @ts-expect-error FIXME
  const value = window[name] || process.env[name]
  if (value == null || value === '' || value === `%${name}%`) {
    return null
  }

  return value
}

const date = readEnv('REACT_APP_BUILD_TIME')
const commit = readEnv('REACT_APP_GIT_SHA1')
const version = readEnv('REACT_APP_VERSION')
const stage = readEnv('REACT_APP_STAGE')
const buildId = readEnv('REACT_APP_BUILD_ID')

const EnvAppInfoContainer: React.FC = () => (
  <AppInfo commit={commit} date={date} version={version} stage={stage} buildId={buildId} />
)

export default EnvAppInfoContainer
