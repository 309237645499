/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PageVisibility from 'react-page-visibility'

import type { Order } from '../../common/interfaces'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

const useStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background,
    color: theme.palette.text.primary,
    marginTop: 28,
    marginLeft: 10,
    marginRight: 10
  },
  video: {
    maxWidth: '100%',
    height: 'auto'
  },
  textContainer: {
    marginBottom: 28
  },
  titleText: {
    ...theme.typography.boldText,
    textAlign: 'center'
  },
  descriptionText: {
    ...theme.typography.normalText,
    textAlign: 'center'
  }
}))

interface OrderTeaserVideoProps {
  order: Order
}

export const OrderTeaserVideo: React.FC<OrderTeaserVideoProps> = ({ order }) => {
  const { t } = useOnSiteTranslation()
  const classes = useStyle()
  const [ isVideoPlaying, setIsVideoPlaying ] = useState<boolean>(false)
  const { linkTeaserVideo } = order

  if (!linkTeaserVideo) {
    return null
  }

  const videoElement = document.getElementById('teaserVideo')

  const handleVisibilityChange = (isVisible: boolean): void => {
    if (videoElement && videoElement instanceof HTMLVideoElement) {
      if (isVisible) {
        if (isVideoPlaying) {
          videoElement.play()
        }
      } else {
        setIsVideoPlaying(!videoElement.paused)
        videoElement.pause()
      }
    }
  }

  const isNativeIOS =
    (/iPad|iPhone|iPod/).test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        <Typography className={classes.titleText}>{t('mediaContent.title')}</Typography>
        <Typography className={classes.descriptionText}>{t('mediaContent.description')}</Typography>
      </Box>

      <video
        id="teaserVideo"
        className={classes.video}
        controls
        autoPlay={isNativeIOS}
        preload="metadata"
        src={linkTeaserVideo}
      />
      <PageVisibility onChange={handleVisibilityChange} />
    </Box>
  )
}
