import queryString from 'query-string'

import {convertAuthToApiToken} from '../tools'
import {AuthRequestProvider, AuthenticatorResponse, LoginFlow, LoginStorage} from '../types'

// NanoID throws when the native Crypto API is not available.
// As this module is cross-imported by different platforms we have to make
// sure that it does not throw where it is not being used e.g. in React Native/App.
// @ts-ignore
const crypto = typeof window != 'undefined' && (window.crypto || window.msCrypto)
const nanoid = crypto ? require('nanoid') : require('nanoid/non-secure')

// This class takes care of communicating with the Authenticator App (send/recv redirects)
export class BrowserLoginFlow implements LoginFlow {
  constructor(storage: LoginStorage, authRequestProvider: AuthRequestProvider) {
    this.storage = storage
    this.authRequestProvider = authRequestProvider
  }

  storage: LoginStorage
  authRequestProvider: AuthRequestProvider
  isRedirecting = false

  async logout(redirect = true) {
    await this.storage.resetToken()
    if (redirect) {
      this.redirectToAuthenticator()
    }
  }

  /**
   * Redirects to the Authenticator application for letting the user log-in.
   */
  async redirectToAuthenticator(path?: string, countryId?: string) {
    if (this.isRedirecting) {
      return
    }

    console.log('Redirecting to login...')
    console.log('countryId=', countryId)
    this.isRedirecting = true

    const csrfState = nanoid()

    await this.storage.setFlow({
      state: csrfState,
      search: window.location.search
    })

    const scope = await this.authRequestProvider.getScope()
    const client = await this.authRequestProvider.getClient()
    const baseUrl = await this.authRequestProvider.getUrl()

    const parsedBase = queryString.parseUrl(baseUrl)

    const authConfig = queryString.stringify({
      // inherit query params from baseUrl
      ...parsedBase.query,

      state: csrfState,
      redirect_uri: window.location.href,

      // These 'params' are send over via URL as GET parameters.
      // Somehow it seems like it was decided to use a special snake_case version here.
      // Internally we always use camelCase as it seems. This means we have to do
      // a lof of error-prone mapping in both directions.
      scope,
      client_id: client.id,
      client_secret: client.secret,
      client_name: client.name,
      ...(countryId && {countryCode: countryId})
    })

    // FIXME: either we use real URLs everywhere or not... but right now we used mixed
    // approaches over the product spectrum. Sometimes path fragments, sometimes domains,
    // sometimes full URLs.
    const authUrl = `${parsedBase.url}${path || ''}?${authConfig}`

    // eslint-disable-next-line require-atomic-updates
    window.location.href = authUrl
  }

  /**
   * Processes the URL parameters added when coming back from a successful login,
   * stores them locally for the following API requests.
   */
  async initFromAuthenticator() {
    const parsed: AuthenticatorResponse = queryString.parse(window.location.search)

    // This is useful when the initial login happens, then we retrieve
    // the data from the current URL by the central authenticator and
    // have to manually store them in storage.
    if (parsed.state) {
      const flowValues = await this.storage.getFlow()

      if (parsed.state === flowValues.state) {
        // Extract token from response

        if (parsed.access_token && parsed.refresh_token) {
          const token = convertAuthToApiToken({
            access_token: parsed.access_token,
            refresh_token: parsed.refresh_token
          })
          // Store the rest using the native method
          await this.storage.setToken(token)

          // Remove our own fields which are just use temporary during
          // exchange with the authentication system.
          await this.storage.resetFlow()

          const cleanUrl = window.location.href.replace(window.location.search, flowValues.search)

          window.history.replaceState({}, '', cleanUrl)
        } else {
          console.warn('Received no access or refresh token')
        }
      } else {
        console.warn("Got CSRF state, but it's different from the one we expected!")
        console.warn(`Values: ${parsed.state} vs. ${flowValues.state}`)
      }
    }
  }
}
