import {Chip, makeStyles} from '@material-ui/core'
import React from 'react'

export type ChipVariant = 'secondary' | 'primary'

interface StatusChip {
  color?: ChipVariant
  label: string
}

const useStyles = makeStyles((theme) => ({
  primary: {
    height: '24px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light
  },
  secondary: {
    height: '24px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.light
  }
}))

const StatusChip: React.FC<StatusChip> = ({color = 'primary', label}) => {
  const classes = useStyles()
  return <Chip className={classes[color]} label={label.toLowerCase()} />
}

export default StatusChip
