import {Checkbox, CheckboxProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useInlineCheckboxStyles = makeStyles({
  root: {
    padding: 0,
    borderRadius: 0,
    color: '#093e54',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  }
})

interface InlineCheckboxType {
  props?: any
}
const InlineCheckbox: React.FC<CheckboxProps | InlineCheckboxType> = (props) => {
  const classes = useInlineCheckboxStyles()

  return (
    // Prepare for cells where we have a click handler on the whole row. We have
    // to make sure to not execute the callback there.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span onClick={(event) => event.stopPropagation()}>
      <Checkbox
        data-test-id="multiple-download-checkbox"
        classes={classes}
        disableRipple
        color="default"
        {...props}
      />
    </span>
  )
}

export {InlineCheckbox}
