import {Branding} from '../Branding.types'

import LogoLehighHanson from './icons/Logo-Lehigh-Hanson-09-07-58-089.svg'

const brand: Branding = {
  nationalBrandName: 'Lehigh Hanson',
  nationalLogoUrl: LogoLehighHanson,
  nationalLogoTitle: 'Lehigh Hanson',

  contactEmail: 'customerportal@lehighhanson.com',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Hub',

  safetyDataSheetLink: 'https://www.heidelbergcement.de/de/sdb',

  country: 'US'
}

export default brand
