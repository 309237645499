import React from 'react'

import type { Order } from '../../common/interfaces'
import { OrderOverviewTab } from '../OrderOverviewTab'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

interface OrderMapTabProps {
  order: Order
}

export const OrderMapTab: React.FC<OrderMapTabProps> = ({ order }) => {
  const { t } = useOnSiteTranslation()
  const { screenshotLinkMap } = order

  const dataImageLink = `data:image/gif;base64,${screenshotLinkMap}`
  return <OrderOverviewTab description={t('mapTab.description')} screenshot={dataImageLink} />
}
