import {InputLabel, InputLabelProps} from '@material-ui/core'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  menuLabel: {
    fontSize: '12px',
    lineHeight: '14px',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'scale(1) translate(24px, 0)'
  },
  menuLabelFocused: {
    color: 'rgba(255, 255, 255, 0.6) !important'
  }
}))

interface TitleInputLabelProps {
  className: string
  InputLabelProps: InputLabelProps
}

const TitleInputLabel: React.FC<TitleInputLabelProps> = ({
  className: classNameProp,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <InputLabel
      shrink={false}
      classes={{
        focused: classes.menuLabelFocused
      }}
      className={classNames(classes.menuLabel, classNameProp)}
      {...otherProps}
    />
  )
}

export default TitleInputLabel
