import {Button, CircularProgress, ButtonProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  progressButtonWrapper: {
    position: 'relative',
    display: 'inline-block'
  },
  buttonProgress: {
    position: 'absolute',
    bottom: theme.spacing(),
    left: '50%',
    marginLeft: -12
  }
}))

interface Props extends ButtonProps {
  loading?: boolean
}

const LoadingButton: React.FC<Props> = ({
  loading,
  children,
  className: classNameProp,
  disabled,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.progressButtonWrapper, classNameProp)}>
      <Button
        disabled={loading || disabled}
        {...otherProps}
        startIcon={loading || disabled ? <LockIcon /> : null}
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default LoadingButton
