import {TextField, makeStyles, TextFieldProps} from '@material-ui/core'
import classNames from 'classnames'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {HCThemeType as HcTheme} from '../HCTheme.types'

import Typography from './Typography'

const useStyles = makeStyles((theme: HcTheme) => ({
  root: {
    width: '100%'
  },
  error: {
    borderColor: `${theme.palette.error.main}!important`
  },
  inputContainer: {
    width: '100%',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
      '& $textFontFace': {
        textDecoration: 'underline'
      },
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primarySoft
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  input: {
    width: '100%'
  }
}))

interface Props {
  label: string
  value: string | undefined
  placeholder: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  name?: string
  required?: boolean
  requiredLabel?: string
  multiLine?: boolean
  inputProps?: any
  helperText?: string
}

export const Textfield: React.FC<Props & TextFieldProps> = ({
  label,
  value,
  placeholder,
  onChange,
  error,
  name,
  required,
  requiredLabel,
  multiLine = false,
  inputProps,
  helperText,
  ...TextFieldProps
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classNames(classes.inputContainer, error ? classes.error : null)}>
        <TextField
          label={label}
          name={name}
          className={classes.input}
          value={value}
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          multiline={multiLine}
          inputProps={inputProps}
          helperText={helperText}
          {...TextFieldProps}
        />
      </div>
      {required ? (
        <Typography variant="caption" color="secondary" component="span">
          {requiredLabel ? requiredLabel : t('required')}
        </Typography>
      ) : null}
    </div>
  )
}
