import {Collapse, List, ListItem, ListItemText} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import flags from '../flags'

import {ExpandIcon} from './ExpandIcon'
import Typography from './Typography'

interface Locale {
  code: string
  name: string
}

export interface Locales {
  defaultLocale: string
  locales: Locale[] | null
  localeListLabel: string
}

interface LanguageSwitcherProps {
  locales: Locales
  onSelectLanguage: (language: string) => void
}

const getLocaleCode = (locales: Locale[], localeCode: string) => {
  if (!locales) {
    return null
  }

  return locales?.find((locale) => locale.code === localeCode)?.code
}

const useStyles = makeStyles((theme) => ({
  flag: {
    width: 25,
    height: 16,
    marginRight: `${theme.spacing(0.5)}px`
  }
}))

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({locales, onSelectLanguage}) => {
  const classes = useStyles()
  const [isLangOpen, setIsLangOpen] = useState<boolean>(false)
  const handleLanguageChange = (code: string) => {
    onSelectLanguage(code)
    setIsLangOpen(false)
  }

  const {t} = useTranslation()

  const selectableLocales = () => {
    if (!locales?.locales) {
      return []
    }
    return locales.locales.filter((locale) => locale.code !== locales.defaultLocale)
  }

  if (selectableLocales().length === 0) {
    return null
  }

  return (
    <div
      style={{
        background: 'white'
      }}
    >
      <ListItem button onClick={() => setIsLangOpen(!isLangOpen)}>
        <img className={classes.flag} src={flags[locales?.defaultLocale]} alt="" />
        <Typography>
          {t(`locales.${getLocaleCode(locales?.locales ?? [], locales?.defaultLocale)}`)}
        </Typography>
        <ExpandIcon open={isLangOpen} onClick={() => setIsLangOpen(!isLangOpen)} />
      </ListItem>
      <Collapse in={isLangOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <Typography component="span" customColor="textPrimarySoft" variant="body2">
              {locales.localeListLabel}
            </Typography>
          </ListItem>
          {selectableLocales()?.map((locale: Locale) => (
            <ListItem key={locale.code} button onClick={() => handleLanguageChange(locale.code)}>
              <img className={classes.flag} src={flags[locale.code]} alt={locale.name} />
              <ListItemText primary={t(`locales.${locale.code}`)} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  )
}
