import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ScheduleAndMapBackground } from '../../assets'
import { getStoreLink } from '../../common/utils'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

const useStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    backgroundImage: `url(${ScheduleAndMapBackground})`,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
    flexDirection: 'column'
  },
  picture: {
    width: '100%',
    maxWidth: 400
  },
  description: {
    ...theme.typography.normalText,
    color: 'white',
    fontSize: 18,
    marginTop: 21,
    textAlign: 'center'
  },
  appStoreLink: {
    ...theme.typography.normalText,
    color: 'white',
    fontSize: 18,
    marginTop: 21,
    textAlign: 'center',
    textDecoration: 'underline'
  }
}))
interface OrderOverviewTabProps {
  description: string
  screenshot: string
}

const openStoreLink = (): void => {
  window.location.href = getStoreLink(window.navigator.userAgent)
}

export const OrderOverviewTab: React.FC<OrderOverviewTabProps> = ({ description, screenshot }) => {
  const classes = useStyle()
  const { t } = useOnSiteTranslation()

  return (
    <Box className={classes.container}>
      <img className={classes.picture} src={screenshot} alt="" />
      <Typography className={classes.description}>{description}</Typography>
      <Typography className={classes.appStoreLink} onClick={openStoreLink}>
        {t('common.getOnSiteNow')}
      </Typography>
    </Box>
  )
}
