import React from 'react'
import { LogoOnSiteWhiteTrans, MobileHeaderButton } from '@hconnect/uikit'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import type { Order } from '../../common/interfaces'
import { recordAndOpen } from '../../common/analytics'
import { getStoreLink } from '../../common/utils'
import { Colors } from '../../common/constants'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: Colors.TealDark,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
    width: 31,
    height: 31,
    margin: 16
  },

  buttonText: {
    ...theme.typography.button,
    color: Colors.White,
    padding: 20
  }
}))

interface OrderHeaderProps {
  order?: Order
}

export const OrderHeader: React.FC<OrderHeaderProps> = ({ order }) => {
  const { t } = useOnSiteTranslation()
  const classes = useStyle()
  return (
    <Box className={classes.container}>
      <img className={classes.logo} src={LogoOnSiteWhiteTrans} alt="LogoOnSiteWhiteTrans" />
      <Box className={classes.buttonText}>
        <MobileHeaderButton
          caption={t('common.getOnSiteNow')}
          onClick={() => {
            recordAndOpen(
              'previewOnStoreHeaderLinkClicked',
              getStoreLink(window.navigator.userAgent),
              order
            )
          }}
        />
      </Box>
    </Box>
  )
}
