import LogoAlliance from '../../Assets/BrandingLogos/LogoAlliance.svg'
import {Branding} from '../Branding.types'

const brand: Branding = {
  nationalBrandName: 'Alliance Construction Materials Ltd.',
  nationalLogoUrl: LogoAlliance,
  nationalLogoTitle: 'Alliance',
  nationalUrl: 'https://www.concrete.hk',
  contactEmail: 'support@hconnect.digital',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Alliance',

  country: 'HK'
}

export default brand
