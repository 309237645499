import React, { useEffect, useMemo, useState } from 'react'
import axios, { AxiosInstance } from 'axios'
import { CircularProgress, CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { HelmetProvider } from 'react-helmet-async'
import moment from 'moment'

import { OnSiteTheme } from './common/OnSiteTheme'
import { getGlobalVars } from './index.global'
import { Localization } from './locales'
import { getBrowserLanguage } from './common/utils'

interface ProviderProps {
  children: any
}

export interface StateContext {
  publicApi: AxiosInstance
}

export const ContextState = React.createContext<StateContext>({
  publicApi: axios.create()
})

export const useGlobal = () => React.useContext(ContextState)

const modifiedTheme = createMuiTheme({
  ...OnSiteTheme,
  palette: {
    ...OnSiteTheme.palette,
    text: {
      ...OnSiteTheme.palette.text,
      secondary: '#628390'
    }
  }
})

/* istanbul ignore next */
export const Provider = ({ children }: ProviderProps) => {
  const [ content, setContent ] = useState(<CircularProgress />)

  // create global variables exactly once
  const { publicApi } = useMemo(() => getGlobalVars(), [])
  useEffect(() => {
    Localization()
      .then((result) => {
        const lang = getBrowserLanguage()
        moment.locale(lang)
        setContent(<>{children}</>)
      })
      .catch((error) => console.error(error))
  }, [ children ])

  return (
    <HelmetProvider>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@400;900&display=swap"
        rel="stylesheet"
      />
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

      <meta name="format-detection" content="telephone=yes" />
      <style />
      <ContextState.Provider
        value={{
          publicApi
        }}
      >
        <ThemeProvider theme={modifiedTheme}>
          <CssBaseline />
          {content}
        </ThemeProvider>
      </ContextState.Provider>
    </HelmetProvider>
  )
}
