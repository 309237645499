import {Button, makeStyles, Box} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'

import {useEditTableProps} from './EditTable'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  }
}))
export const EditAddButton: React.FC<{label: string}> = ({label}) => {
  const classes = useStyles()
  const {
    pendingRowState: {isPending, setIsPending}
  } = useEditTableProps()
  const handleClick = () => setIsPending(true)
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        key={'add-button'}
        variant="contained"
        color="primary"
        size="large"
        disabled={isPending}
        onClick={handleClick}
        className={classes.button}
        startIcon={<AddIcon />}
        data-test-id={`add-button-${label}`}
      >
        {label}
      </Button>
    </Box>
  )
}
