import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Delivery} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {DeliveriesSchemaID, MaterialLocationsSchemaID} = SchemaIDs

// API defined endpoints
export const getDeliveries = (api: AxiosInstance) => (
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Delivery[]> => {
  const config = {params, schemaId: DeliveriesSchemaID, ...axiosConfig}
  return api.get('/deliveries', config)
}

export const getDelivery = (api: AxiosInstance) => (
  deliveryId: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Delivery> => {
  const config = {params, schemaId: DeliveriesSchemaID, ...axiosConfig}
  return api.get(`/deliveries/${deliveryId}`, config)
}

export const getDeliveryMaterialLocation = (api: AxiosInstance) => (
  deliveryId: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<any> => {
  const config = {params, schemaId: MaterialLocationsSchemaID, ...axiosConfig}
  return api.get(`/deliveries/${deliveryId}/materialLocations`, config)
}
