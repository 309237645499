import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Customer} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {CustomerSchemaID, CustomersSchemaID} = SchemaIDs

// API defined endpoints
export const getCustomerById = (api: AxiosInstance) => async (
  customerId: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Customer> => {
  const config = {params, schemaId: CustomerSchemaID, ...axiosConfig}
  const response = await api.get<Customer>(`/customers/${customerId}`, config)
  return response.data
}

// deprecated, use getCustomerById
export const getCustomer = getCustomerById

export const getCustomersByNumber = (api: AxiosInstance) => async (
  customerNumber: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Customer[]> => {
  const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
  const response = await api.get<Customer[]>(`/customers?customerNumber=${customerNumber}`, config)
  return response.data
}

export const getCustomersByPayerId = (api: AxiosInstance) => async (
  payerNumber: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Customer[]> => {
  const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
  const response = await api.get<Customer[]>(`/customers?payerId=${payerNumber}`, config)
  return response.data
}
