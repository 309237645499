import React from 'react'

import type { Order } from '../../common/interfaces'
import { OrderOverviewTab } from '../OrderOverviewTab'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'

interface OrderScheduleTabProps {
  order: Order
}

export const OrderScheduleTab: React.FC<OrderScheduleTabProps> = ({ order }) => {
  const { t } = useOnSiteTranslation()
  const { screenshotLinkSchedule } = order
  const dataImageLink = `data:image/png;base64,${screenshotLinkSchedule}`
  return <OrderOverviewTab description={t('scheduleTab.description')} screenshot={dataImageLink} />
}
