import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Order} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {OrdersSchemaID, OrderSchemaID, MaterialLocationsSchemaID} = SchemaIDs

export const getOrders = (api: AxiosInstance) => (
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Order[]> => {
  const config = {params, schemaId: OrdersSchemaID, ...axiosConfig}
  return api.get('/orders', config)
}

export const getOrder = (api: AxiosInstance) => (
  orderId: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Order> => {
  const config = {params, schemaId: OrderSchemaID, ...axiosConfig}
  return api.get(`/orders/${orderId}`, config)
}

export const getOrderMaterialLocations = (api: AxiosInstance) => (
  orderId: string,
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<any> => {
  const config = {params, schemaId: MaterialLocationsSchemaID, ...axiosConfig}
  return api.get(`/orders/${orderId}/materialLocations`, config)
}
