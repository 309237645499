export const stripHostName = (hostName: string): string =>
  hostName
    // remove stages
    .replace(/-(qa|dev|uat|e2e|dptest)/, '')
    // create filename-friendly-name
    .replace(/\./g, '-')

export const getBrandingIdentifierFromUrl = (urlInstance: URL): string | null => {
  return stripHostName(urlInstance.hostname)
}
