import {CssBaseline} from '@material-ui/core'
import {ThemeProvider, createMuiTheme, fade} from '@material-ui/core/styles'
import {TypographyOptions} from '@material-ui/core/styles/createTypography'
import {Shadows} from '@material-ui/core/styles/shadows'
import {merge} from 'lodash'
import React from 'react'

import {Fonts} from './Fonts'
import {HcPaletteOptions, HcThemeOptions, HcTypographyOptions} from './HCTheme.types'

// Palette is used to set the colors of material-ui components such as
// Button, TextField etc.
// FIXME: how to extend the palette by additional colors? Reference baseColors directly?
export const palette: HcPaletteOptions = {
  primary: {
    main: '#29aaff',
    light: '#30beff',
    dark: '#106594',
    contrastText: '#ffffff'
  },

  secondary: {
    main: '#7f9ba6',
    light: '#b2c3c9',
    dark: '#00374d',
    contrastText: '#ffffff'
  },

  error: {
    main: '#ff4f00',
    light: '#ff7233',
    dark: '#cf4a0e',
    contrastText: '#ffffff'
  },

  warning: {
    main: '#ffaf00', // not a material-ui color
    light: '#ffc74d', // not a material-ui color
    dark: '#cf970e', // not a material-ui color
    contrastText: '#00374d' // not a material-ui color
  },

  success: {
    main: '#1ac463',
    light: '#44e68a',
    dark: '#128945',
    contrastText: '#ffffff'
  },

  grey: {
    25: '#fbfbfb',
    50: '#f7f7f7',
    100: '#e7e7e7'
  },

  text: {
    secondary: '#ffffff',
    secondarySoft: '#99afb7', // not a material-ui color
    primary: '#00374d',
    primarySoft: '#628390', // not a material-ui color
    disabled: '#7f9ba6',
    link: '#29aaff',
    hint: '#000000'
  },

  divider: '#e7e7e7',
  dividerDark: '#979797',
  dividerStrong: '#1a4b5f',
  dividerOnBlue: 'rgba(255, 255, 255, 0.5)',

  common: {
    black: '#000000',
    white: '#FFFFFF'
  },

  slate: {
    main: '#5e6f9e', // not a material-ui color
    light: '#8d99bb', // not a material-ui color
    dark: '#414d6e', // not a material-ui color
    contrastText: '#ffffff' // not a material-ui color
  },

  background: {
    paper: '#ffffff',
    default: '#00374d'
  }
}

export const gradients = {
  sky: `linear-gradient(-90deg, ${palette.slate.main} 0%, ${palette.primary.main} 100%)`,
  nightSky: `linear-gradient(-90deg, ${palette.slate.main} 0%, ${palette.background.default} 100%)`,
  emerald: `linear-gradient(-90deg, ${palette.success.dark} 0%, ${palette.success.main} 100%)`
}

// Shadows for different elevations
const shadows: Shadows = [
  'none',
  'none',
  '0px 2px 2px 0px rgba(0,0,0,0.06),0px 2px 4px 2px rgba(0,0,0,0.08)',
  '0px 2px 8px 0px rgba(0,0,0,0.06),0px 2px 4px 2px rgba(0,0,0,0.08)',
  '0px 4px 4px 0px rgba(0,0,0,0.1),0px 2px 4px 2px rgba(0,0,0,0.08)',
  '0px 6px 8px 0px rgba(0,0,0,0.12),0px 2px 6px 2px rgba(0,0,0,0.08)',
  '0px 6px 18px 0px rgba(0,0,0,0.12),0px 4px 6px 2px rgba(0,0,0,0.08)',
  '0px 6px 28px 0px rgba(0,0,0,0.12),0px 4px 10px 2px rgba(0,0,0,0.08)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  // end of styleguide
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
  '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)'
]

export const typography: HcTypographyOptions = {
  // typography used by material ui components
  h1: {
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '1.166em'
  },
  h2: {
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '1.28em',
    textTransform: 'uppercase'
  },
  h3: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '1.666em'
  },
  h4: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.1875em'
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '1.214em'
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '1.375em'
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '1.714em'
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px'
  },
  button: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '1.166em',
    letterSpacing: '0.042em',
    textTransform: 'uppercase'
  },

  // additional typography variants
  captionBold: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '1.166em',
    letterSpacing: '0.0666em'
  },

  formLabel: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '1.166em',
    letterSpacing: '0.0833em',
    textTransform: 'uppercase'
  }
}

export const HCTheme = createMuiTheme({
  palette,
  shadows,
  typography: {
    ...typography,
    fontFamily: ['"Roboto"', 'sans-serif'].join(',')
  }
})

// Override Component default Props
HCTheme.props = {
  MuiButtonBase: {
    disableRipple: true
  },
  MuiButton: {
    variant: 'outlined'
  },
  MuiFormControl: {
    variant: 'outlined'
  },
  MuiSvgIcon: {
    fontSize: 'small'
  }
}

// Override Component default styles
HCTheme.overrides = {
  MuiCssBaseline: {
    '@global': {
      '*::-webkit-scrollbar': {
        width: '4px',
        height: '12px'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 4px rgba(0,0,0,0.3)',
        backgroundColor: palette.primary.dark
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: palette.primary.main
      },
      body: {
        backgroundColor: palette.common.white
      },
      '@font-face': [
        Fonts.roboto400Woff,
        Fonts.roboto400Woff2,
        Fonts.roboto400Svg,
        Fonts.roboto400Ttf,
        Fonts.roboto500Woff,
        Fonts.roboto500Woff2,
        Fonts.roboto500Svg,
        Fonts.roboto500Ttf,
        Fonts.roboto700Woff,
        Fonts.roboto700Woff2,
        Fonts.roboto700Svg,
        Fonts.roboto700Ttf,
        Fonts.MaterialIconsRegularSvg
      ]
    }
  },
  MuiTypography: {
    root: {
      fontFamily: ['"Roboto"', 'sans-serif'].join(',')
    }
  },
  MuiButton: {
    root: {
      borderRadius: '7px',
      ...typography.button
    },
    outlined: {
      padding: '10px 34px 11px 32px',
      '&:hover': {
        boxShadow: HCTheme.shadows[4]
      },
      '&:active': {
        boxShadow: HCTheme.shadows[0]
      }
    },
    outlinedPrimary: {
      backgroundColor: palette.primary.main,
      border: 'none',
      color: palette.primary.contrastText,
      '&:hover': {
        backgroundColor: palette.primary.light,
        border: 'none',
        color: palette.primary.contrastText,
        '@media (hover:none)': {
          backgroundColor: palette.primary.dark
        }
      },
      '&:active, &:focus': {
        backgroundColor: palette.primary.dark,
        border: 'none',
        color: palette.primary.contrastText
      },
      '&$disabled': {
        backgroundColor: palette.secondary.light,
        border: 'none',
        color: palette.grey[50]
      }
    },
    outlinedSecondary: {
      color: palette.secondary.dark,
      borderColor: palette.secondary.main,
      '&:hover': {
        backgroundColor: palette.background.paper,
        borderColor: palette.secondary.dark
      },
      '&:active, &:focus': {
        borderColor: palette.secondary.light
      },
      '&$disabled': {
        color: palette.text.disabled,
        borderColor: palette.secondary.light
      }
    },
    colorInherit: {
      color: palette.secondary.contrastText,
      borderColor: palette.secondary.light,
      '&:hover': {
        borderColor: palette.secondary.contrastText
      },
      '&:active, &:focus': {
        borderColor: palette.secondary.main
      },
      '&$disabled': {
        color: palette.text.disabled,
        borderColor: palette.secondary.main
      }
    }
  },
  MuiIconButton: {
    root: {
      padding: '0.5em',
      border: '1px solid currentColor',
      boxShadow: '0 0 1px 0px currentColor inset, 0 0 1px 0px currentColor',
      color: palette.secondary.dark,
      borderColor: palette.secondary.main,
      transition: `background-color ${HCTheme.transitions.duration.short} ${HCTheme.transitions.easing.easeInOut},
                        box-shadow ${HCTheme.transitions.duration.short} ${HCTheme.transitions.easing.easeInOut},
                        border ${HCTheme.transitions.duration.short} ${HCTheme.transitions.easing.easeInOut}`,
      '&:hover': {
        backgroundColor: palette.background.paper,
        boxShadow: HCTheme.shadows[4],
        borderColor: palette.secondary.dark
      },
      '&:active': {
        boxShadow: HCTheme.shadows[0],
        borderColor: palette.secondary.light
      },
      '&$disabled': {
        color: palette.text.disabled,
        borderColor: palette.secondary.main
      }
    },
    colorInherit: {
      color: palette.secondary.light,
      borderColor: palette.secondary.light,
      '&:hover': {
        backgroundColor: palette.secondary.dark,
        boxShadow: HCTheme.shadows[4],
        borderColor: palette.secondary.light
      },
      '&:active': {
        boxShadow: HCTheme.shadows[0],
        borderColor: palette.secondary.main
      },
      '&$disabled': {
        color: palette.text.disabled,
        borderColor: palette.secondary.main
      }
    }
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: fade(palette.primary.main, 0.4)
    }
  },
  MuiSwitch: {
    root: {
      marginLeft: -8,
      marginRight: -8,

      '&$sizeSmall': {
        marginLeft: -3,
        marginRight: -3
      }
    },
    colorSecondary: {
      '&$checked': {
        '& + $track': {
          backgroundColor: palette.success.main,
          opacity: 1
        },

        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    switchBase: {
      border: 'none',
      boxShadow: 'none',

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    },
    track: {
      backgroundColor: palette.common.black
    },
    thumb: {
      color: palette.common.white,
      boxShadow: HCTheme.shadows[3]
    }
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  MuiInputLabel: {
    root: {
      color: palette.secondary.dark
    },
    outlined: {
      transform: 'translate(14px, 13px) scale(1)'
    }
  },
  MuiOutlinedInput: {
    root: {
      background: palette.grey[50],
      borderRadius: '29px'
    },
    input: {
      padding: '13px 13px 9px'
    },
    multiline: {
      padding: '13px 13px 9px'
    },
    adornedStart: {
      paddingLeft: 8
    },
    inputAdornedStart: {
      borderTopRightRadius: 29,
      borderBottomRightRadius: 29
    },
    adornedEnd: {
      paddingRight: 8
    },
    inputAdornedEnd: {
      borderTopLeftRadius: 29,
      borderBottomLeftRadius: 29
    }
  },
  MuiSelect: {
    outlined: {
      borderRadius: '29px',
      '&:focus': {
        borderRadius: '29px'
      }
    }
  },
  MuiCheckbox: {
    root: {
      color: palette.secondary.main,
      border: 'none',
      boxShadow: 'none'
    },
    colorPrimary: {
      borderColor: palette.secondary.dark,
      color: palette.secondary.dark,
      '&:hover, &:active, &$checked': {
        borderColor: palette.secondary.dark,
        color: palette.secondary.dark
      },
      '&$disabled': {
        borderColor: palette.secondary.light,
        color: palette.secondary.light
      }
    }
  },
  MuiInputAdornment: {
    root: {
      color: palette.secondary.main,
      '& >*': {
        padding: 2,
        color: palette.secondary.contrastText,
        background: palette.secondary.main,
        borderRadius: '50%'
      }
    }
  },
  MuiTab: {
    root: {
      maxWidth: 'auto',
      '&:hover:not($selected)': {
        color: palette.text.link
      }
    },
    textColorPrimary: {
      color: palette.text.primary
    },
    textColorSecondary: {
      color: palette.text.secondary
    }
  },
  MuiFormHelperText: {
    root: {
      color: 'inherit'
    }
  }
}

export const withTheme = (props: HcThemeOptions) => (
  Component: React.ComponentClass<any> | React.FC<any>
) =>
  class extends React.Component<any> {
    static displayName = `HcThemed(${Component.displayName || Component.name})`

    render(): JSX.Element {
      return (
        <ThemeProvider theme={merge(HCTheme, props)}>
          <CssBaseline />
          <Component {...this.props} />
        </ThemeProvider>
      )
    }
  }
