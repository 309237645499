import {CircularProgress, Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React, {ComponentType, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {fetchBranding} from './Branding.actions'
import {selectBranding, selectBrandingConfig} from './Branding.selector'
import {Branding} from './Branding.types'

interface BrandingContext extends Branding {
  NationalLogo: ComponentType<any>

  LocalLogo: ComponentType<any>

  contactEmailHref: string
}

const Context = React.createContext<BrandingContext>({
  NationalLogo: () => null,
  LocalLogo: () => null,
  contactEmailHref: ''
})
Context.displayName = 'Branding'

export const useBranding = () => useContext(Context)

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    zIndex: 99999,
    width: '100vw',
    height: '100vh',
    background: 'rgba(255, 255, 255, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  nationalLogo: {
    maxWidth: 156
  }
})

/**
 * BrandingProvider will load branding-configuration based on the current hostname.
 * You can override the hostname detection by setting up an localStorage key/value with:
 *      localStorage.setItem('branding_override', 'hub.heidelbergcement.com')
 *
 * @param children
 * @class
 */
export const BrandingProvider = ({children}: {children: any}) => {
  const classes = useStyles()

  const branding: Branding = useSelector((state: any) => selectBrandingConfig(state))
  const loading = useSelector((state: any) => selectBranding(state).isFetching)
  const error = useSelector((state: any) => selectBranding(state).error)

  const reduxDispatch = useDispatch()

  // FIXME: The issue reported here by eslint is actually an issue and should be fixed!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchBrandingLocal = (): any => reduxDispatch(fetchBranding())

  useEffect(() => {
    fetchBrandingLocal()
  }, [fetchBrandingLocal])

  const {
    nationalLogoUrl,
    nationalBrandName,
    nationalLogoTitle,
    localLogoUrl,
    localBrandName,
    localLogoTitle,
    contactEmail,
    contactEmailSubject
  } = branding
  const contactEmailHref = contactEmail
    ? `mailto:${contactEmail}${
        contactEmailSubject && `?subject=${encodeURIComponent(contactEmailSubject)}`
      }`
    : ''

  // update PageTitle
  const {t} = useTranslation()
  useEffect(() => {
    document.title = t('hub.title', {nationalBrandName, localBrandName})
  }, [t, nationalBrandName, localBrandName])

  return (
    <Context.Provider
      value={{
        ...branding,
        NationalLogo: (props) =>
          nationalLogoUrl ? (
            <img
              className={classNames(classes.nationalLogo)}
              src={nationalLogoUrl}
              alt={nationalBrandName}
              title={nationalLogoTitle}
              {...props}
            />
          ) : null,
        LocalLogo: (props) =>
          localLogoUrl ? (
            <img src={localLogoUrl} alt={localBrandName} title={localLogoTitle} {...props} />
          ) : null,
        contactEmailHref
      }}
    >
      {!error && children}
      {!loading && error && (
        <div className={classes.overlay}>
          <h1>{t('branding.configurationfailure.title')}</h1>
          <p>{t('branding.configurationfailure.description')}</p>
          <p>
            {t('branding.configurationfailure.pleaseretry')}
            <Link onClick={() => window.location.reload()}>
              {t('branding.configurationfailure.retrylink')}
            </Link>
          </p>
        </div>
      )}
      {loading && (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      )}
    </Context.Provider>
  )
}
