import {Box, CircularProgress, Typography, makeStyles} from '@material-ui/core'
import type {AxiosResponse} from 'axios'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import PageVisibility from 'react-page-visibility'

import {isCookieBannerNotRequired, record} from '../common/analytics'
import {OrderTypes} from '../common/constants'
import type {Order} from '../common/interfaces'
import {OnSiteTheme} from '../common/OnSiteTheme'
import {getConfig} from '../common/oreoSettings'
import {useOnSiteTranslation} from '../common/useOnSiteTranslation'
import {getOrderType, getUrlParameterValue} from '../common/utils'
import {OrderFooter} from '../components/OrderFooter'
import {OrderHeader} from '../components/OrderHeader'
import {useGlobal} from '../index.provider'
import {ErrorPage} from '../pages/ErrorPage'
import {OrderCardPage} from '../pages/OrderCardPage'

import {Banner, isCookieBannerShown, useOreo} from '@hconnect/uikit'

const useStyle = makeStyles((theme: any) => ({
  '@global': {
    body: {
      'overflow-y': 'scroll',
      margin: 0,
      width: '100%',
      height: '100%'
    }
  },
  container: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.contrastText,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    justifyContent: 'center',
    alignContent: 'center'
  },
  header: {
    flexShrink: 0
  },
  main: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 30,
    flexGrow: 1,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  footer: {
    flexShrink: 0
  },
  refreshContainer: {
    backgroundColor: 'rgba(41, 170, 255, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    padding: '11px 21px 11px 21px ',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  refreshTimeText: {
    ...theme.typography.normalText,
    color: 'rgb(0, 55, 77)',
    fontSize: '16px',
    padding: '10px 0px 10px 0px'
  },
  refreshButton: {
    textDecoration: 'underline',
    ...theme.typography.normalText,
    color: 'rgb(0, 55, 77)',
    fontSize: '16px',
    padding: '10px 0px 10px 0px',
    cursor: 'pointer'
  }
}))

const {OrderTypeCancelled} = OrderTypes

export const OrderLayout: React.FC = () => {
  const [accepted] = useOreo()

  const countryCode = getUrlParameterValue('countryCode')
  const [showBanner, setShowBanner] = useState<boolean>(
    !!((!countryCode || !isCookieBannerNotRequired(countryCode)) && isCookieBannerShown(accepted))
  )

  const [order, setOrder] = useState<Order>()
  const [errorDescription, setErrorDescription] = useState<string>()
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const [lastRefreshTime, setLastRefreshTime] = useState<moment.Moment | null>()
  const refreshTimeoutId = useRef<number | null>(null)

  const classes = useStyle()
  const {t} = useOnSiteTranslation()
  const id = getUrlParameterValue('id')
  const {publicApi} = useGlobal()

  const getRefreshingText = (): string => {
    if (isRefreshing) {
      return t('refreshInfo.firstTimeFetch')
    }
    if (!isRefreshing && !lastRefreshTime) {
      return t('refreshInfo.notYetFetched')
    }
    if (lastRefreshTime) {
      return t('refreshInfo.updatedAt', {formattedLastRefreshTime: lastRefreshTime.format('LT')})
    }
    return t('refreshInfo.notYetFetched')
  }

  const fetchOrderPreview = useCallback(
    async (id: string): Promise<any> => {
      try {
        setIsRefreshing(true)
        const response: AxiosResponse<Array<Order>> = await publicApi.get(`/previews/${id}`)
        const orderPreview = response.data[0]
        const {orderStatus} = orderPreview
        if (getOrderType(orderStatus) === OrderTypeCancelled) {
          setErrorDescription(t('errorPage.cancelledOrder'))
          return
        }
        setOrder(orderPreview)
        setLastRefreshTime(moment())
      } catch (error) {
        console.error(error)
        setErrorDescription(t('errorPage.expiredLink'))
      } finally {
        setIsRefreshing(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publicApi]
  )

  const refresh = (): void => {
    if (id) {
      fetchOrderPreview(id)
    }
  }

  const startRefreshLoop = useCallback((): void => {
    setIsRefreshing(true)
    if (id) {
      fetchOrderPreview(id).then(() => {
        refreshTimeoutId.current = window.setTimeout(startRefreshLoop, 1000 * 60)
      })
    }
  }, [fetchOrderPreview, id])

  const stopRefreshLoop = useCallback((): void => {
    if (refreshTimeoutId.current !== null) {
      window.clearTimeout(refreshTimeoutId.current)
      refreshTimeoutId.current = null
    }
  }, [])

  const handleVisibilityChange = (isPageVisible: boolean): void => {
    if (isPageVisible) {
      startRefreshLoop()
    } else {
      stopRefreshLoop()
    }
  }

  useEffect(() => {
    if (id) {
      startRefreshLoop()
    } else {
      setErrorDescription(t('errorPage.commonError'))
    }
    return () => {
      stopRefreshLoop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicApi, id, fetchOrderPreview, startRefreshLoop, stopRefreshLoop])

  useEffect(() => {
    if (!order) {
      return
    }
    const {
      orderId,
      shippingAddress: {siteId, country},
      customer: {customerId},
      orgUnitId,
      orderStatus
    } = order
    const eventData = {
      orderId,
      siteId,
      customerId,
      country,
      orgUnitId,
      orderStatus
    }
    record('previewOnOrderLoaded', eventData, order)
  }, [order])

  let content = <CircularProgress />

  if (order) {
    content = <OrderCardPage order={order} />
  } else if (errorDescription) {
    content = <ErrorPage errorDescription={errorDescription} />
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <OrderHeader order={order} />
          <Box className={classes.refreshContainer}>
            <Typography className={classes.refreshTimeText}>{getRefreshingText()}</Typography>
            {!isRefreshing && (
              <Typography onClick={refresh} className={classes.refreshButton}>
                {t('refreshInfo.buttonRefreshText')}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.main}>{content}</Box>
        <Box className={classes.footer}>
          <OrderFooter order={order} />
        </Box>
        <PageVisibility onChange={handleVisibilityChange} />
      </Box>
      {showBanner && (
        <Banner
          labels={getConfig(t).bannerLabels}
          policyInformation1={getConfig(t).policyInformation1}
          policyInformation2={getConfig(t).policyInformation2}
          topics={getConfig(t).topics}
          theme={OnSiteTheme}
          onClose={() => setShowBanner(false)}
        />
      )}
    </>
  )
}
