import {Branding} from '../Branding.types'

const brand: Branding = {
  nationalBrandName: 'nationalBrandName',
  nationalLogoTitle: 'nationalLogoTitle',

  contactEmail: 'hchubdev@gmail.com',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'pageTitle',

  country: 'US'
}

export default brand
