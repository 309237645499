import PropTypes from 'prop-types'
import React from 'react'

import ErrorPaper from './ErrorPaper'

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean
}

/**
 * ErrorBoundary
 * Will catch errors thrown by the inner component and show an ErrorPaper
 * ErrorBoundary is only possible with Class-component!
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true
    }
  }

  retry = (e: React.MouseEvent) => {
    e.preventDefault()
    this.setState({hasError: false})
  }

  render() {
    const {children, ...props} = this.props
    const {hasError} = this.state
    return hasError ? <ErrorPaper {...props} onRetry={this.retry} /> : children
  }
}

export default ErrorBoundary
