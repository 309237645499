import {ButtonBase} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import classNames from 'classnames'
import React from 'react'

import Typography from '../../Typography'

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'stretch',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  navTitle: {
    marginLeft: theme.spacing()
  }
}))

interface TitleNavProps {
  className?: string
  title: string
  buttonProps?: any
  labelProps?: any
  onClick: () => void
}

const TitleNav: React.FC<TitleNavProps> = ({
  className: classNameProp,
  title,
  buttonProps,
  labelProps,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classNames(classes.button, classNameProp)} {...otherProps}>
      <ArrowBackIcon />
      <Typography className={classes.navTitle} variant="h2" color="inherit" {...labelProps}>
        {title}
      </Typography>
    </ButtonBase>
  )
}

export default TitleNav
