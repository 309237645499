import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Reports} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {ProjectsReportSchemaID} = SchemaIDs

export const getReportsOrdersByStatus = (api: AxiosInstance) => (
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Reports> => {
  const config = {params, schemaId: ProjectsReportSchemaID, ...axiosConfig}
  return api.get('/reports/ordersByStatus', config)
}

export const getReportsOrdersBySites = (api: AxiosInstance) => (
  params: StringParams = {},
  axiosConfig?: AxiosRequestConfig
): Promise<Reports> => {
  const config = {params, schemaId: ProjectsReportSchemaID, ...axiosConfig}
  return api.get('/reports/ordersBySites', config)
}
