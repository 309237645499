import {Button, Paper} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between'
  },
  button: {
    textTransform: 'uppercase',
    fontWeight: 500,
    flex: '1 auto',
    justifyContent: 'flex-end'
  },
  dark: {
    border: '1px solid rgba(255, 255, 255, 0.5)',
    color: 'rgba(255, 255, 255, 0.67)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    '& $button': {
      color: 'rgb(94, 111, 158)',
      '&:hover': {
        background: '#002c3d'
      }
    }
  },
  primaryDark: {
    border: '1px solid rgba(255, 255, 255, 0.5)',
    color: 'rgba(255, 255, 255, 0.67)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    '& $button': {
      color: 'rgb(41, 170, 255)',
      '&:hover': {
        background: '#002c3d'
      }
    }
  },
  light: {
    border: '1px solid rgba(127, 155, 166, 0.5)',
    color: 'rgba(0, 55, 77, 0.96)',
    backgroundColor: 'rgba(41, 170, 255, 0.04)',
    '& $button': {
      color: 'rgb(41, 170, 255)'
    }
  }
}))

interface Props {
  className?: string
  variant?: 'dark' | 'light' | 'primaryDark'
  errorText?: string
  showRetry?: boolean
  onRetry?: (e: React.MouseEvent) => void
  retryLinkText?: string
}

const ErrorPaper: React.FC<Props> = ({
  className: classNameProp,
  variant = 'dark',
  errorText,
  showRetry = true,
  onRetry,
  retryLinkText,
  ...props
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Paper className={classNames(classes.errorPaper, classes[variant], classNameProp)} {...props}>
      {errorText || t('errorboundary.error')}
      {showRetry && (
        <Button className={classes.button} variant="text" onClick={onRetry}>
          {retryLinkText || t('errorboundary.retryLink')}
        </Button>
      )}
    </Paper>
  )
}

export default ErrorPaper
