import {Theme} from '@material-ui/core/styles/createMuiTheme'
import {ThemeProvider} from '@material-ui/styles'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import {BannerProps} from './common/interfaces'
import {getAcceptedCookies, LocaleStorageCookie, useOreo} from './common/storage'
import {Banner as OreoBanner} from './components/Banner'

const ONE_YEAR_IN_MS = 31556926000
interface BannerComponentProps extends BannerProps {
  theme: Theme
}

const Banner: React.FC<BannerComponentProps> = ({
  labels,
  policyInformation1,
  policyInformation2,
  topics,
  onClose,
  theme
}) => (
  <ThemeProvider theme={theme}>
    <OreoBanner
      labels={labels}
      topics={topics}
      policyInformation1={policyInformation1}
      policyInformation2={policyInformation2}
      onClose={onClose}
    />
  </ThemeProvider>
)

function isCookieBannerShown(accepted: LocaleStorageCookie) {
  return (
    isEmpty(accepted) ||
    (!isEmpty(accepted) &&
      (!Array.isArray(accepted.acceptedKeys) ||
        accepted.acceptedKeys.length < 1 ||
        (accepted.date && Date.now() - accepted.date > ONE_YEAR_IN_MS)))
  )
}

export {Banner, useOreo, getAcceptedCookies, isCookieBannerShown}
