import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef} from 'react'
import {TextField} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import classNames from 'classnames'
import i18next from 'i18next'

const styles = (theme) => ({
  inputLabelFocused: {},
  inputLabel: {
    '&:not($inputLabelFocused)': {
      transform: 'translate(52px, 24px) scale(1);'
    }
  },
  input: {},
  phoneNumberTextField: {
    '& .iti': {
      width: '100%'
    },
    '& .iti input': {
      width: 'calc(100% - 36px)'
    },
    '& .iti.iti--allow-dropdown input': {
      width: 'calc(100% - 58px)'
    }
  }
})

export const validationErrorCodeDescription = (errorCode) => {
  switch (errorCode) {
    case 1:
      return 'invalidCountryCode'

    case 2:
      return 'tooShort'

    case 3:
      return 'tooLong'

    case 4:
      return 'notANumber'

    case 0:
    // fall through

    default:
      return 'invalidNumber'
  }
}

const PhoneNumberTextField = forwardRef(
  (
    {
      classes,
      className,
      onChange,
      options = {},
      shrink,
      InputLabelProps,
      inputRef: inputRefProp,
      ...otherProps
    },
    ref
  ) => {
    const itiRef = useRef()
    const inputRef = useRef()
    const setInputRef = (node) => {
      if (typeof inputRefProp === 'function') {
        inputRefProp(node)
      } else if (inputRefProp) {
        inputRefProp.current = node
      }
      inputRef.current = node
    }

    const handleChange = useCallback(() => {
      onChange(
        {
          inputValue: inputRef.current.value,
          selectedCountryCode: itiRef.current.getSelectedCountryData().iso2
        },
        itiRef.current
      )
    }, [onChange])

    const localeToIso = (userLocale) => {
      if (!userLocale.includes('-')) {
        return userLocale
      }
      return userLocale.split('-')[1]
    }

    const getUserCountryCode = (callback) => {
      try {
        return callback(localeToIso(i18next.language))
      } catch (err) {
        console.log('TCL: getUserCountryCode -> err', err)
      }
    }
    useEffect(() => {
      const defaults = {
        preferredCountries: ['us', 'ca', 'au', 'de', 'ru'],
        autoPlaceholder: 'off',
        initialCountry: 'auto',
        geoIpLookup: getUserCountryCode
      }

      // FIXME: remove try catch and implement proper solution to the problem
      try {
        itiRef.current = intlTelInput(inputRef.current, {
          ...defaults,
          ...options
        })
      } catch (e) {
        console.log(e)
      }

      if (!window.intlTelInputUtils) {
        import('intl-tel-input/build/js/utils').then(() => {
          itiRef.current.handleUtils()
        })
      }
      return () => {
        itiRef.current?.destroy()
      }
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      const inputEl = inputRef.current
      inputEl.addEventListener('countrychange', handleChange)
      return () => {
        inputEl.removeEventListener('countrychange', handleChange)
      }
    }, [handleChange])

    useImperativeHandle(ref, () => itiRef.current)

    return (
      <TextField
        type="tel"
        autoComplete="tel"
        {...otherProps}
        onChange={handleChange}
        inputRef={setInputRef}
        className={classNames(classes.phoneNumberTextField, className)}
        InputLabelProps={{
          classes: {
            focused: classes.inputLabelFocused,
            shrink: classes.inputLabelFocused,
            root: classes.inputLabel
          },
          ...InputLabelProps
        }}
      />
    )
  }
)
PhoneNumberTextField.displayName = 'PhoneNumberTextField'

export default withStyles(styles)(PhoneNumberTextField)
