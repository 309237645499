/* eslint-disable complexity */
import {Box, Grid, Popover, makeStyles, Theme} from '@material-ui/core'
import classnames from 'classnames'
import moment, {Moment} from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  DayPickerRangeController,
  DayPickerSingleDateController,
  isSameDay
} from 'react-dates'
import {useTranslation} from 'react-i18next'

import {rangeDate} from '../../../Tools/range'
import Typography from '../../Typography'

import {DatePickerActionButtons} from './DatePickerActionButtons'
import {DatePickerButton} from './DatePickerButton'
import {DatePickerCalendar} from './DatePickerCalendar'
import {DatePickerQuickSelection} from './DatePickerQuickSelection'
import {DatePickerTextField} from './DatePickerTextField'
import {sameMomentDate} from './DatePickerUtil'
import {DateRange, Dates, FocusedInput, QuickSelectionItem} from './types'

const useStyle = makeStyles((theme: Theme) => ({
  quickSelection: {},
  popover: {
    marginTop: theme.spacing(2),
    marginLeft: '-20px',
    color: theme.palette.primary.contrastText,
    backgroundColor: '#001E2A'
  },
  popoverLight: {
    marginTop: theme.spacing(2),
    marginLeft: '-20px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.white
  },
  calendarContainer: {
    flexShrink: 1,
    lineHeight: 'normal'
  },
  calendarContainerHidden: {
    visibility: 'hidden'
  },
  datePickerContainer: {
    padding: 0,
    display: 'inline-block',
    position: 'relative',
    zIndex: 999
  },
  fullWidth: {
    width: '100%'
  }
}))

interface Props {
  handleDateChange: (args: Dates) => void
  startDate?: Moment | null
  endDate?: Moment | null
  calendarClasses?: string
  textClasses?: string
  noQuickSelection?: boolean
  getQuickSelectionItems?: (language: string) => QuickSelectionItem[]
  singleSelection?: boolean
  label?: React.ReactNode
  maxDayRange?: number
  maxDayRangeInfoMessage?: string
  dateControllerProps?: Partial<DayPickerSingleDateController & DayPickerRangeController>
  hideClearButton?: boolean
  hideApplyButton?: boolean
  showDatePickerButton?: boolean
  noPastDates?: boolean
  onLight?: boolean
  availableDateRange?: DateRange
  dateExceptions?: Moment[]
  fullWidth?: boolean
  customMessage?: string
  showDateChange?: boolean
  showDateChangeLabel?: string
}

const DatePicker: React.FC<Props> = ({
  handleDateChange,
  calendarClasses,
  noQuickSelection,
  getQuickSelectionItems,
  singleSelection,
  maxDayRange,
  maxDayRangeInfoMessage,
  startDate: startDateProp,
  endDate: endDateProp,
  dateControllerProps,
  textClasses,
  hideClearButton,
  hideApplyButton,
  showDatePickerButton,
  noPastDates,
  onLight = false,
  availableDateRange,
  dateExceptions,
  fullWidth,
  customMessage,
  showDateChange,
  showDateChangeLabel,
  ...restProps
}) => {
  const classes = useStyle()
  const {t, i18n} = useTranslation()
  const {language} = i18n

  useEffect(() => {
    moment.locale(language)
  }, [language])

  const defaultGetQuickSelectionItems = (moment: Moment, locale: string): QuickSelectionItem[] => [
    {
      title: t('datePicker.todaySelection'),
      type: 'singleDate',
      dates: [moment.locale(locale).startOf('day'), moment.locale(locale).endOf('day')]
    },
    {
      title: t('datePicker.weekSelection'),
      type: 'range',
      dates: [moment.locale(locale).startOf('isoWeek'), moment.locale(locale).endOf('isoWeek')]
    },
    {
      title: t('datePicker.monthSelection'),
      type: 'range',
      dates: [moment.locale(locale).startOf('month'), moment.locale(locale).endOf('month')]
    }
  ]

  const startDate = startDateProp ? moment(startDateProp) : null
  const endDate = endDateProp ? moment(endDateProp) : null

  const [selectedStartDate, setSelectedStartDate] = useState(startDate)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)

  useEffect(() => {
    setSelectedStartDate(startDateProp ? moment(startDateProp) : null)
    setSelectedEndDate(endDateProp ? moment(endDateProp) : null)
  }, [startDateProp, endDateProp])

  const [focusedInput, setFocusedInput] = useState(FocusedInput.StartDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  let text = t('datePicker.chooseDate')
  if (startDate != null && endDate != null) {
    if (sameMomentDate(startDate, endDate)) {
      text = endDate.locale(language).format('L')
    } else {
      text = rangeDate([startDate, endDate], language, 'L')
    }
  }
  const handleQuickSelectionItem = (dates: Dates) => {
    handleDateChange({startDate: dates.startDate, endDate: dates.endDate})
    setOpen(false)
  }

  const handleDatesChange = (dates: Dates) => {
    const startOfDate = dates.startDate ? moment(dates.startDate).startOf('day') : null
    const endOfDate = dates.endDate ? moment(dates.endDate).endOf('day') : null
    setSelectedStartDate(startOfDate)
    setSelectedEndDate(endOfDate)
    if (focusedInput === FocusedInput.EndDate && endOfDate) {
      handleDateChange({startDate: startOfDate, endDate: endOfDate})
      setOpen(false)
    }
  }

  const handleSingleDateChange = (date: Moment | null) => {
    if (!date) return
    const startOfDate = moment(date).startOf('day')
    const endOfDate = moment(date).endOf('day')
    setSelectedStartDate(startOfDate)
    setSelectedEndDate(endOfDate)

    handleDateChange({startDate: startOfDate, endDate: endOfDate})
    setOpen(false)
  }

  const handleClear = () => {
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setFocusedInput(FocusedInput.StartDate)
  }
  const handleApply = () => {
    const startOfDate = moment(selectedStartDate).startOf('day')
    const endOfDate =
      selectedEndDate !== null
        ? moment(selectedEndDate).endOf('day')
        : moment(selectedStartDate).endOf('day')
    handleDateChange({startDate: startOfDate, endDate: endOfDate})
    setOpen(false)
  }
  const clearDisabled = selectedStartDate === null && selectedEndDate === null
  const applyDisabled =
    sameMomentDate(selectedStartDate, startDate) && sameMomentDate(selectedEndDate, endDate)

  let isAfterMaxDayRange: boolean
  const isOutsideMaxDayRange = (day: Moment, maxDayRange: number) => {
    if (focusedInput === FocusedInput.EndDate) {
      setSelectedEndDate(null)
      isAfterMaxDayRange = day.isAfter(moment(selectedStartDate).clone().add(maxDayRange, 'days'))
      return isInclusivelyAfterDay(day, moment(selectedStartDate).clone().add(maxDayRange, 'days'))
    }
  }

  const renderMaxDayRangeInfo = (maxDayRange: number, maxDayRangeInfoMessage: string) => {
    if (!isAfterMaxDayRange) {
      return
    }
    return (
      <Box paddingLeft={3}>
        <Typography customColor="textSecondarySoft" variant="body2">
          {maxDayRange} {maxDayRangeInfoMessage}
        </Typography>
      </Box>
    )
  }

  return (
    <div
      className={[classes.datePickerContainer, fullWidth && classes.fullWidth]
        .filter((key) => key)
        .join()}
      {...restProps}
    >
      {!showDatePickerButton ? (
        <DatePickerTextField
          buttonRef={anchorRef}
          focus={open}
          setFocus={setOpen}
          setFocusedInput={setFocusedInput}
          text={text}
          label={restProps.label}
          textClasses={textClasses}
        />
      ) : (
        <DatePickerButton
          buttonRef={anchorRef}
          focus={open}
          setFocus={setOpen}
          setFocusedInput={setFocusedInput}
          label={text}
          isDateChange={showDateChange}
          isDateChangeLabel={showDateChangeLabel}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{paper: onLight ? classes.popoverLight : classes.popover}}
      >
        <Grid
          item
          container
          direction="column"
          className={classnames(classes.calendarContainer, calendarClasses)}
          style={{
            width: noQuickSelection || singleSelection ? 214 : 214 + 192
          }}
        >
          <Grid container direction="row">
            <Grid item id="date-picker-range-input">
              <DatePickerCalendar
                onLight={onLight}
                singleSelection={singleSelection}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                focusedInput={focusedInput}
                onFocusChange={() => {
                  setFocusedInput(
                    focusedInput === FocusedInput.StartDate
                      ? FocusedInput.EndDate
                      : FocusedInput.StartDate
                  )
                }}
                onDatesChange={handleDatesChange}
                onSingleDateChange={handleSingleDateChange}
                dateControllerProps={{
                  ...dateControllerProps,
                  ...(dateExceptions && {
                    isDayBlocked: (day: Moment) =>
                      dateExceptions.some((dateException) => isSameDay(day, dateException))
                  }),
                  ...(availableDateRange && {
                    isOutsideRange: (day: Moment) =>
                      day.isBefore(availableDateRange.from) || day.isAfter(availableDateRange.to)
                  }),
                  ...(maxDayRange && {
                    isOutsideRange: (day: Moment) => isOutsideMaxDayRange(day, maxDayRange),
                    ...(maxDayRangeInfoMessage && {
                      renderCalendarInfo: () =>
                        renderMaxDayRangeInfo(maxDayRange, maxDayRangeInfoMessage)
                    }),
                    calendarInfoPosition: 'bottom'
                  }),
                  ...(noPastDates && {
                    isOutsideRange: (day: Moment) => isInclusivelyBeforeDay(day, moment())
                  }),
                  ...(customMessage && {
                    renderCalendarInfo: () => (
                      <Box mb={2} mx={3}>
                        <Typography color="secondary" variant="caption">
                          {customMessage}
                        </Typography>
                      </Box>
                    )
                  })
                }}
              />
            </Grid>
            {noQuickSelection || singleSelection ? null : (
              <Grid item className={classes.quickSelection}>
                <DatePickerQuickSelection
                  onLight={onLight}
                  items={
                    getQuickSelectionItems
                      ? getQuickSelectionItems(language)
                      : defaultGetQuickSelectionItems(moment(), language)
                  }
                  {...(selectedStartDate && {
                    selectedStartDate
                  })}
                  {...(selectedEndDate && {
                    selectedEndDate
                  })}
                  handleItemClick={handleQuickSelectionItem}
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <DatePickerActionButtons
              onLight={onLight}
              clearDisabled={clearDisabled}
              handleClear={handleClear}
              applyDisabled={applyDisabled}
              handleApply={handleApply}
              hideClearButton={hideClearButton}
              hideApplyButton={hideApplyButton}
            />
          </Grid>
        </Grid>
      </Popover>
    </div>
  )
}

export {DatePicker}
