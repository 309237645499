import {ButtonBase, ButtonBaseTypeMap, ExtendButtonBase} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    // TODO: should be #e7e7e7 but is not part of theme, yet. gray-colors are calculated.
    //  maybe UX can change these to #e5e5e5 which would match "theme.palette.grey[100]"
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
      '& $textFontFace': {
        textDecoration: 'underline'
      },
      // TODO: missing hover colors in theme: #e7e7e7
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '&:focus': {
      // background: theme.palette.common.white,
      // borderColor: theme.palette.primary.main
    }
  },
  long: {
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(0.16),
    marginLeft: theme.spacing(0.5)
  }
}))

interface TitleButtonProps {
  className?: string
  buttonRef: React.MutableRefObject<any>
  onClick(): void
  long?: boolean
}

const TitleButton: React.FC<TitleButtonProps> = ({
  className,
  buttonRef,
  long = false,
  ...otherProps
}) => {
  const classes = useStyles()
  if (long) {
    return <ButtonBase className={classes.long} ref={buttonRef} {...otherProps} />
  }
  return (
    <ButtonBase className={classNames(classes.button, className)} ref={buttonRef} {...otherProps} />
  )
}

export default TitleButton
