import {Select, SelectProps, makeStyles} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import React from 'react'

const useStyle = makeStyles((theme) => ({
  iconStyle: {
    position: 'absolute',
    top: '3px',
    right: '0',
    color: 'rgba(255, 255, 255, 0.96)',
    pointerEvents: 'none'
  },
  menuDropdown: {
    '& .MuiPaper-root': {
      background: '#001E2A',
      marginTop: theme.spacing(5.5),
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      boxShadow: 'none',
      '& > ul': {
        padding: `${theme.spacing(0.5)}px 0`
      }
    }
  },
  menuSelect: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.19',
    color: 'rgba(255, 255, 255, 0.96)'
  },
  select: {
    '&:focus,&:active': {
      backgroundColor: ['transparent', '!important']
    }
  }
}))

interface TitleSelectProps {
  className?: string
}

const TitleSelect: React.FC<TitleSelectProps & SelectProps> = ({
  className: classNameProp,
  ...otherProps
}) => {
  const classes = useStyle()
  return (
    <Select
      disableUnderline
      displayEmpty
      MenuProps={{
        getContentAnchorEl: null,
        className: classes.menuDropdown
      }}
      className={classNames(classes.menuSelect, classNameProp)}
      classes={{
        select: classes.select
      }}
      IconComponent={() => <ExpandMore className={classes.iconStyle} fontSize="default" />}
      {...otherProps}
    />
  )
}

export default TitleSelect
