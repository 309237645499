import {AxiosRequestConfig} from 'axios'
import queryString from 'query-string'

import {getURLEncodedContentTypeHeader} from '../../tools'
import {ClientConfig} from '../../types'

export const createClientCredentialsInterceptor = (
  clientConfig: Pick<ClientConfig, 'authRequestProvider'>
) => async (config: AxiosRequestConfig) => {
  const headers = config.headers || {}
  const urlEncodedHeader = getURLEncodedContentTypeHeader()
  const urlEncodeBody = headers['Content-Type'] === urlEncodedHeader['Content-Type']

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {id, secret} = await clientConfig.authRequestProvider.getClient()

  const body = {
    ...config.data,
    client_id: id,
    client_secret: secret
  }

  const data = urlEncodeBody ? queryString.stringify(body) : body

  const modifiedConfig = {
    ...config,
    headers,
    data
  }

  return modifiedConfig
}
