import {Branding} from '../Branding.types'

import LogoHCBosnia from './icons/Logo-Kakanj-Cement-BA.svg'

const brand: Branding = {
  nationalBrandName: 'KakanjCement',
  nationalLogoUrl: LogoHCBosnia,
  nationalLogoTitle: 'KakanjCement',

  contactEmail: 'podrska-hub@heidelbergcement.com',
  contactEmailSubject: 'Poruka sa portala za kupce',

  pageTitle: 'KakanjCement',

  country: 'BA'
}

export default brand
