import {Branding} from '../Branding.types'

import LogoHCRussia from './icons/Logo-HC-Russia-09-07-58-064.svg'

const brand: Branding = {
  nationalBrandName: 'HeidelbergCement',
  nationalLogoUrl: LogoHCRussia,
  nationalLogoTitle: 'HeidelbergCement',
  nationalUrl: 'https://www.heidelbergcement.ru/ru',
  contactEmail: 'support@hconnect.digital',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'HeidelbergCement',

  country: 'RU'
}

export default brand
