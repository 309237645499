import {Branding} from '../Branding.types'

import LogoHeidelbergCement from './icons/Logo-DE.svg'

const brand: Branding = {
  nationalBrandName: 'HeidelbergCement AG',
  nationalLogoUrl: LogoHeidelbergCement,
  nationalUrl: 'https://www.heidelbergcement.de/de',
  nationalLogoTitle: 'HeidelbergCement AG',
  contactEmail: 'hub@heidelbergcement.de',
  contactEmailSubject: 'Kundenanliegen zum Hub Portal',
  pageTitle: 'HeidelbergCement AG',
  country: 'DE',
  dispatchPhoneNumber: '09471 70753500'
}

export default brand
