/**
 * Generated interface based on Api Blueprint definitions
 * find more at: https://api.hce.heidelbergcement.com/API/hub#orders-details-of-an-order-get
 */

import {Customer} from './Customer'
import {LineItem} from './Invoice'

export interface Order {
  /**
   * Reference to the customer
   */
  customer: Customer
  /**
   * Customer reference text used by customer to identify the order, e.g. customer purchase
   * order reference
   */
  customerReference?: null | string
  /**
   * Indicates if further exection of the order is on hold.
   */
  isHalted?: boolean
  lineItems: LineItem[]
  /**
   * Unique Identifier for the order
   */
  orderId: string
  /**
   * Reference to business key of an order in the backend system
   */
  orderNumber: string
  /**
   * Status information of the order
   */
  orderStatus: OrderStatus
  /**
   * The date the order was placed
   */
  placedDate?: null | string
  /**
   * Reference to a project/contract this order is linked to
   */
  projectId?: null | string
  /**
   * Reference to a business key of project/contract this order is linked to
   */
  projectNumber?: null | string
  /**
   * The requested schedule of the delivery
   */
  schedules?: any[]
  /**
   * The address of the destination/site the order is delivered to
   */
  shippingAddress: ShippingAddress
  /**
   * The date the order is expected to be executed
   */
  shippingDate: string
  /**
   * Indicates if an order is delivered to the customer or picked-up by the customer
   */
  shippingType?: ShippingType
  supplementItems?: any[]
}

/**
 * Status information of the order
 */
export enum OrderStatus {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  InProgress = 'inProgress',
  PartiallyDelivered = 'partiallyDelivered',
  PartiallyShipped = 'partiallyShipped',
  Pending = 'pending',
  Reserved = 'reserved',
  Shipped = 'shipped'
}

/**
 * The address of the destination/site the order is delivered to
 */
export interface ShippingAddress {
  /**
   * address informationn of the site
   */
  city?: null | string
  /**
   * address informationn of the site
   */
  country?: null | string
  /**
   * Reference to the customer
   */
  customerId: string
  /**
   * Latitude of the site
   */
  lat?: null | string
  /**
   * Longitute of the site
   */
  lng?: null | string
  /**
   * address informationn of the site
   */
  postalCode?: null | string
  /**
   * Unique identifier of the site
   */
  siteId: string
  /**
   * Textual description of the project that helps to clearly identify a given site
   */
  siteName?: null | string
  /**
   * buiness key of the site
   */
  siteNumber?: null | string
  /**
   * address informationn of the site
   */
  state?: null | string
  /**
   * Street address informationn of the site
   */
  street?: null | string
}

/**
 * Indicates if an order is delivered to the customer or picked-up by the customer
 */
export enum ShippingType {
  Collect = 'collect',
  Deliver = 'deliver'
}
