import {makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import BoxedContainer from './BoxedContainer'
import PageTitle from './PageTitle'
import Typography from './Typography'

const useStyle = makeStyles((theme) => ({
  pageContainer: {
    flexGrow: 1,
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`
  },
  default: {},
  sheet: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary
  },
  subPage: {
    padding: '0'
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },
  headerActions: {
    minHeight: 46
  }
}))

interface PageProps {
  headerActionContent?: any
  title?: any
  aboveTitle?: React.ReactNode | string
  children?: any
  variant?: 'default' | 'sheet' | 'subPage'
  boxed?: boolean
  className?: string
  classNameHeader?: string
  classNameBody?: string
  'data-test-id'?: string
}

const Page: React.FC<PageProps> = ({
  headerActionContent,
  title,
  aboveTitle,
  children,
  variant = 'default',
  boxed = true,
  'data-test-id': dataTestId,
  classNameHeader,
  classNameBody,
  className
}) => {
  const classes = useStyle()

  const content = (
    <>
      {aboveTitle !== undefined && (
        <Typography variant="h4" customColor="textSecondarySoft">
          {aboveTitle}
        </Typography>
      )}
      {(title || headerActionContent) && (
        <div className={classNames(classes.pageHeader, classNameHeader)}>
          <PageTitle data-test-id={dataTestId}>{title}</PageTitle>
          <div className={classes.headerActions}>{headerActionContent}</div>
        </div>
      )}
      <div className={classNames(classNameBody)}>{children}</div>
    </>
  )

  return (
    <div className={classNames(classes.pageContainer, classes[variant], className)}>
      {boxed ? <BoxedContainer>{content}</BoxedContainer> : content}
    </div>
  )
}

export default Page
