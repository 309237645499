import {Branding} from '../Branding.types'

import LogoHymix from './icons/Logo-Hymix-09-07-58-077.svg'

const brand: Branding = {
  nationalBrandName: 'Hymix',
  nationalLogoUrl: LogoHymix,
  nationalLogoTitle: 'Hymix',
  nationalUrl: 'https://www.hymix.com.au',
  contactEmail: 'digital@hymix.com.au',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Hymix',

  country: 'AU'
}

export default brand
