
import {Button, Box, Tooltip, makeStyles, Theme} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import React, {Ref, useEffect} from 'react'

import Typography from '../../Typography'

import {FocusedInput} from './types'

interface Props {
  focus: boolean
  buttonRef: Ref<HTMLButtonElement>
  setFocus: (focus: boolean) => void
  setFocusedInput: (dateType: FocusedInput) => void
  label: string
  isDateChange?: boolean
  isDateChangeLabel?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `${theme.spacing(0.125)}px solid ${theme.palette.primary.main}`
  }
}))

export const DatePickerButton: React.FC<Props> = ({
  focus,
  buttonRef,
  setFocus,
  setFocusedInput,
  label,
  isDateChange,
  isDateChangeLabel
}) => {
  const classes = useStyles()
  return (
    <Tooltip open={isDateChange} title={isDateChangeLabel ?? ''} placement="right">
      <Button
      variant="text"
      ref={buttonRef}
      id="date-picker-toggle-button"
      onClick={() => {
        setFocus(!focus)
        setFocusedInput(FocusedInput.StartDate)
      }}
      className={isDateChange ? classes.button : ''}
    >
      <Box display="flex" alignItems="center">
        <Typography customVariant="formLabel" color="primary">
          {label}
        </Typography>
        <Box mx={0.25} />
        <DateRangeIcon fontSize="inherit" color="primary" />
      </Box>
    </Button>
    </Tooltip>

  )
}
