import {Box, makeStyles, Typography} from '@material-ui/core'
import React from 'react'

import {useEditTableProps} from './EditTable'

interface EditTableHeaderProps {
  columns: React.ReactNode[]
  header: string
}

export const useStyles = makeStyles({
  tableHeader: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '1.666em'
  },
  columnHeaderRow: {
    borderBottom: '1px solid rgba(0,0,0,0.5)'
  },
  columnHeader: {
    padding: '8px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '12pt'
  }
})

export const GridTableHeader: React.FC<
  EditTableHeaderProps & Pick<React.CSSProperties, 'gridTemplateColumns'>
> = ({columns, header, gridTemplateColumns}) => {
  const {tableHeader, columnHeaderRow, columnHeader} = useStyles()
  return (
    <Box display="flex" flexDirection="column">
      <Box py={2}>
        <Typography className={tableHeader}>{header}</Typography>
      </Box>
      <Box display="grid" gridTemplateColumns={gridTemplateColumns} className={columnHeaderRow}>
        {columns.map((node, index) => (
          <div key={`${header}-column-${index}`} className={columnHeader}>
            {node}
          </div>
        ))}
      </Box>
    </Box>
  )
}

export const EditTableHeader: React.FC<EditTableHeaderProps> = (props) => {
  const {
    gridStyle: {gridTemplateColumns}
  } = useEditTableProps()
  return <GridTableHeader gridTemplateColumns={gridTemplateColumns} {...props} />
}
