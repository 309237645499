import {get} from 'lodash'

import {
  BRANDING_FETCH_FAILURE,
  BRANDING_FETCH_REQUEST,
  BRANDING_FETCH_SUCCESS,
  BrandingActionTypes
} from './Action.types'
import {Branding} from './Branding.types'
import {getBrandingIdentifierFromUrl, stripHostName} from './Branding.utils'
import Empty from './branding/empty'
import HubConcreteHk from './branding/hub-concrete-hk'
import HubHansonComAu from './branding/hub-hanson-com-au'
import HubHcrusRu from './branding/hub-hcrus-ru'
import HubHeidelbergCementCom from './branding/hub-heidelbergcement-com'
import HubHeidelbergCementDe from './branding/hub-heidelbergcement-de'
import HubHeidelbergCementRu from './branding/hub-heidelbergcement-ru'
import HubHymixComAu from './branding/hub-hymix-com-au'
import HubKakanjCementBa from './branding/hub-kakanjcement-ba'
import HubLehighHansonCom from './branding/hub-lehighhanson-com'
import Localhost from './branding/localhost'

interface BrandingType {
  [id: string]: Branding
}

const brandings: BrandingType = {
  'hub-concrete-hk': HubConcreteHk,
  'hub-hanson-com-au': HubHansonComAu,
  'hub-hcrus-ru': HubHcrusRu,
  'hub-heidelbergcement-com': HubHeidelbergCementCom,
  'hub-heidelbergcement-ru': HubHeidelbergCementRu,
  'hub-hymix-com-au': HubHymixComAu,
  'hub-lehighhanson-com': HubLehighHansonCom,
  'hub-heidelbergcement-de': HubHeidelbergCementDe,
  'hub-kakanjcement-ba': HubKakanjCementBa,
  empty: Empty,
  localhost: Localhost
}

const getBrandIdentifier = (): string =>
  stripHostName(localStorage.getItem('branding_override') || window.location.hostname)

export const getBrandingFromUrl = (url: URL): Branding => {
  const brandIdentifier = getBrandingIdentifierFromUrl(url) || getBrandIdentifier()
  return get(brandings, brandIdentifier, Empty)
}

export const fetchBrandingRequest = (params: any): BrandingActionTypes => ({
  type: BRANDING_FETCH_REQUEST,
  params
})

export const fetchBrandingSuccess = (data: Branding): BrandingActionTypes => ({
  type: BRANDING_FETCH_SUCCESS,
  data
})

export const fetchBrandingFailure = (error: any) => ({
  type: BRANDING_FETCH_FAILURE,
  error
})

export const fetchBranding = () => async (dispatch: any) => {
  const brandingIdentifier: string = getBrandIdentifier()

  try {
    // const data:Branding = Localhost
    const data: Branding = brandings[brandingIdentifier]
    // const { default: data } = await import(`./branding/${brand}`)
    dispatch(fetchBrandingSuccess(data))
    // const { data } = await axios.get(`/branding/${brand}.json`)
  } catch {
    const data: Branding = Empty
    // const { default: data } = await import(`./branding/empty`)
    dispatch(fetchBrandingSuccess(data))
  }
}
