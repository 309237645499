import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import type { Order } from '../../common/interfaces'
import { useOnSiteTranslation } from '../../common/useOnSiteTranslation'
import { OrderInfoTab } from '../OrderInfoTab'
import { OrderMapTab } from '../OrderMapTab'
import { OrderScheduleTab } from '../OrderScheduleTab'

const useStyle = makeStyles((theme: any) => ({
  container: {
    flexGrow: 1,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.background,
    color: theme.palette.text.primary
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabs: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    alignItems: 'center',
    alignContent: 'center'
  },
  tab: {
    flex: 1
  },
  titleText: {
    ...theme.typography.boldText
  },
  descriptionText: {
    ...theme.typography.normalText
  }
}))

interface OrderOverviewProps {
  order: Order
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

export const OrderOverview: React.FC<OrderOverviewProps> = ({ order }) => {
  const { t } = useOnSiteTranslation()
  const classes = useStyle()
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        <Typography className={classes.titleText}>{t('orderOverview.title')}</Typography>
        <Typography className={classes.descriptionText}>
          {t('orderOverview.description')}
        </Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} aria-label="" className={classes.tabs} centered>
        <Tab label={t('orderOverview.infoTab')} className={classes.tab} />
        <Tab label={t('orderOverview.scheduleTab')} className={classes.tab} />
        <Tab label={t('orderOverview.mapTab')} className={classes.tab} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <OrderInfoTab order={order} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrderScheduleTab order={order} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrderMapTab order={order} />
      </TabPanel>
    </Box>
  )
}
