import {Box, Theme, makeStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {timeFormatter} from '../../Tools'
import Typography from '../Typography'

interface TimeColumnProps {
  label: string
  time: string
}

const TimeColumn: React.FC<TimeColumnProps> = ({label, time}) => {
  const {
    i18n: {language}
  } = useTranslation()

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" customColor="textPrimarySoft">
        {label}
      </Typography>
      <Typography variant="h4" component="span">
        {timeFormatter(moment(time, 'HH:mm:ss'), language)}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
      '& $textFontFace': {
        textDecoration: 'underline'
      },
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '.MuiInput-underline:before': {
      borderBottom: 'none'
    }
  }
}))

interface Props {
  startTimeLabel: string
  startTime: string
  endTimeLabel: string
  endTime: string
}

export const TimeRangePicker: React.FC<Props> = ({
  startTimeLabel,
  startTime,
  endTimeLabel,
  endTime
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box display="flex">
        <TimeColumn label={startTimeLabel} time={startTime} />
        <Box mx={2} />
        <TimeColumn label={endTimeLabel} time={endTime} />
      </Box>
    </div>
  )
}
