import {Divider} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles(({spacing}) => ({
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    marginLeft: spacing(4),
    marginRight: spacing(4)
  }
}))

interface TitleDividerProps {
  className: string
}

const TitleDivider: React.FC<TitleDividerProps> = ({className, ...otherProps}) => {
  const classes = useStyles()
  return (
    <Divider
      className={classNames(classes.divider, className)}
      orientation="vertical"
      {...otherProps}
    />
  )
}

export default TitleDivider
