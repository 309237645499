import {Box} from '@material-ui/core'
import {add} from '@storybook/cli/ts3.5/dist/add'
import React, {useContext, useEffect, useState} from 'react'
import {useForm, UseFormMethods} from 'react-hook-form'

import {EditAddButton} from './EditAddButton'

interface EditTableState {
  methods: UseFormMethods
  gridStyle: Pick<React.CSSProperties, 'gridTemplateColumns' | 'gridAutoFlow' | 'gridAutoColumns'>
  pendingRowState: {
    isPending: boolean
    setIsPending: React.Dispatch<React.SetStateAction<boolean>>
  }
}
interface EditTableProps {
  gridTemplateColumns?: string
  padding?: number
  addRowConfig?: {
    createPendingRow: () => JSX.Element
    addButtonLabel: string
  }
}
const EditTableContext = React.createContext<EditTableState | undefined>(undefined)
EditTableContext.displayName = 'EditTableContext'

export const useEditTableProps = (): EditTableState => {
  const editState = useContext(EditTableContext)
  if (!editState) {
    throw new Error('All EditRow components must be have a parent EditTable in the component tree')
  }
  return editState
}
export const EditTable: React.FC<EditTableProps> = ({
  children,
  gridTemplateColumns,
  padding = 3,
  addRowConfig
}) => {
  const methods = useForm({shouldUnregister: false})
  const [isPending, setIsPending] = useState(false)
  const [pendingEditRow, setPendingEditRow] = useState<null | JSX.Element>(null)
  useEffect(() => {
    if (isPending) setPendingEditRow(addRowConfig?.createPendingRow() ?? null)
    else setPendingEditRow(null)
  }, [addRowConfig, isPending])

  const gridStyle: EditTableState['gridStyle'] = gridTemplateColumns
    ? {gridTemplateColumns}
    : {gridAutoColumns: '1fr', gridAutoFlow: 'column'}

  return (
    <EditTableContext.Provider
      value={{methods, gridStyle, pendingRowState: {isPending, setIsPending}}}
    >
      <Box
        p={padding}
        display="flex"
        flexDirection="column"
        bgcolor="background.paper"
        color="text.primary"
      >
        {children}
        {pendingEditRow && React.cloneElement(pendingEditRow, {pending: true})}
        {addRowConfig && <EditAddButton label={addRowConfig.addButtonLabel} />}
      </Box>
    </EditTableContext.Provider>
  )
}
