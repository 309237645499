import {AxiosRequestConfig} from 'axios'

export const timezoneHeaderInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const posixOffset = new Date().getTimezoneOffset()

  const headers = {
    ...config.headers,
    'X-Timezone-Offset': posixOffset
  }

  return {...config, headers}
}
