import {Icon} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {HCThemeType} from '../../HCTheme.types'

const useStyles = makeStyles((theme: HCThemeType) => ({
  root: {
    fontFamily: 'Material Icons',
    fontSize: (props: MaterialIconsProps) => props.iconSize,
    color: (props: MaterialIconsProps) => props.iconColor
  }
}))

interface MaterialIconsProps {
  iconName: string
  iconColor: string
  iconSize: number
  style?: React.CSSProperties
}

export const MaterialIcons: React.FC<MaterialIconsProps> = (props) => {
  const classes = useStyles(props)
  return (
    <Icon className={classes.root} {...props}>
      {props.iconName}
    </Icon>
  )
}
