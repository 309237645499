import React from 'react'
import { StyleSheet, View } from 'react-native-web'

import { Colors } from '../../common/constants'

interface Segment {
  progressValue: number
  progressColor: string
}

interface HCProgressBarProps {
  segments: Array<Segment>
  totalValue: number
  backgroundColor: string
  barHeight: number
  containerStyle?: Object
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    overflow: 'hidden'
  }
})

export const HCProgressBar: React.FC<HCProgressBarProps> = ({
  segments,
  totalValue,
  backgroundColor,
  barHeight,
  containerStyle
}) => {
  const renderSegments = () => {
    const validSegments = segments.filter((segment) => {
      const { progressValue } = segment

      // Only render segments with progressValues greather than 0
      return progressValue > 0
    })

    let currentProgressValuesSum = 0

    return validSegments.map((segment, index) => {
      const { progressValue, progressColor } = segment
      currentProgressValuesSum += progressValue

      const progressValuePercentageFromTotal = progressValue / totalValue

      const defaultSegmentStyle = {
        flex: progressValuePercentageFromTotal,
        backgroundColor: progressColor
      }
      const segmentStyleWithSeparator = {
        ...defaultSegmentStyle,
        borderRightWidth: 1,
        borderColor: Colors.White
      }
      const segmentStyle =
        currentProgressValuesSum < totalValue ? segmentStyleWithSeparator : defaultSegmentStyle

      /* eslint-disable react/no-array-index-key */
      return <View key={index} style={segmentStyle} />
    })
  }

  const isTotalValueValid = totalValue > 0
  const areSegmentsValuesValid = segments.every((segment) => {
    const { progressValue } = segment
    return progressValue >= 0
  })

  const shouldRenderProgressBar = isTotalValueValid && areSegmentsValuesValid
  const borderRadius = barHeight / 2

  if (!shouldRenderProgressBar) {
    return null
  }

  return (
    <View
      style={[
        containerStyle,
        styles.container,
        { backgroundColor, height: barHeight, borderRadius }
      ]}
    >
      {renderSegments()}
    </View>
  )
}
