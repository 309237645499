import { createMuiTheme } from '@material-ui/core/styles'
import { HCTheme } from '@hconnect/uikit'
import { HcPaletteOptions } from 'react-native-web'

import { Colors } from './constants'

export const palette: HcPaletteOptions = {
  ...HCTheme.palette,
  text: {
    secondary: '#628390',
    secondarySoft: '#99afb7', // not a material-ui color
    primary: '#00374d',
    primarySoft: '#628390', // not a material-ui color
    disabled: '#7f9ba6',
    link: '#29aaff',
    hint: '#000000'
  },
  backgroundDark: Colors.TealDark,
  colors: Colors
}

export const gradients = {
  //@ts-expect-error
  ...HCTheme.gradients
}
//@ts-expect-error
delete HCTheme['shadows']
export const OnSiteTheme = createMuiTheme({
  ...HCTheme,
  palette,
  typography: {
    ...HCTheme.typography,
    h3: {
      fontSize: '14px',
      fontWeight: 800,
      fontFamily: 'Roboto'
    },
    //@ts-expect-error
    primary: {
      fontSize: '14px',
      fontWeight: 800,
      fontFamily: 'Roboto'
    },
    body1: {
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'Roboto'
    },
    body2: {
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'Roboto'
    },
    boldText: {
      fontSize: '14px',
      fontWeight: 800,
      fontFamily: 'Roboto'
    },
    normalText: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      color: palette.text.primary
    },
    lightText: {
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      color: palette.text.disabled
    },
    label: {
      fontSize: '34px',
      fontWeight: 400,
      fontFamily: 'Roboto'
    },
    fontFamily: [ '"Roboto"', 'sans-serif' ].join(',')
  }
})
