import {makeStyles, Theme} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import Typography from './Typography'

const useSectionHeaderStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  title: {
    paddingBottom: theme.spacing(0.25),
    borderBottom: `1px solid ${theme.palette.secondary.dark}`
  },
  first: {
    marginTop: theme.spacing(0)
  }
}))

interface SectionHeaderProps {
  title: string
  caption?: string
  first?: boolean
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({title, caption, first}) => {
  const classes = useSectionHeaderStyles()
  return (
    <div className={classNames(classes.sectionHeader, first ? classes.first : null)}>
      <div className={classes.title}>
        <Typography variant="h3">{title}</Typography>
      </div>
      {caption ? (
        <Typography variant="caption" color="secondary">
          {caption}
        </Typography>
      ) : null}
    </div>
  )
}
