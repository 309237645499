import trim from 'lodash/trim'
import moment from 'moment'
import React from 'react'

import {Order, TruckInformation} from './interfaces'
import {
  getEstimatedTimeOfArrival,
  getInTransitQuantity,
  getOnSiteQuantity,
  getOrderType,
  getPouredQuantity,
  getTruckTimeObject,
  isTruckArrivingInLessThanOneHour
} from './utils'
import {Colors, Fonts, OrderTypes} from './constants'
import {OrderCardMix} from '../components/OrderCardMix'
import {OrderCardQuantityInformation} from '../components/OrderCardQuantityInformation'
import {OrderCardProgressInformation} from '../components/OrderCardProgressInformation'
import {OrderCardTruckInformation} from '../components/OrderCardTruckInformation'
import {OrderCardRMCTestBooked} from '../components/OrderCardRMCTestBooked'

interface TruckTimeAndTitle {
  orderCardTruckTitle: string
  orderCardTruckTime?: string
  orderCardTruckTimeAbbreviation?: string
  orderCardTruckIsMinutes?: boolean
}

const orderCardDefaultStyles = {
  circleTextFontStyles: {
    fontSize: 16,
    ...Fonts.RobotoBold
  },
  labelColor: Colors.TealDark,
  subtitleColor: Colors.TealDark,
  orderTitle: {
    backgroundColor: Colors.White,
    color: Colors.TealDark,
    ...Fonts.RobotoBold,
    fontSize: 16,
    marginBottom: 4
  },
  orderSubtitle: {
    backgroundColor: Colors.White,
    ...Fonts.RobotoBold,
    color: Colors.TealDark,
    fontWeight: '500',
    fontSize: 16,
    paddingBottom: 8
  },
  orderHeaderContainer: {
    backgroundColor: Colors.White,
    marginBottom: 16,
    flexDirection: 'row'
  }
}

type Ti18n = (string, any?) => string

export const getHaltedTitle = (order: Order, t: Ti18n): string => {
  if (
    getPouredQuantity(order) > 0 ||
    getInTransitQuantity(order) > 0 ||
    getOnSiteQuantity(order) > 0
  ) {
    return t('order.haltedAfterTrucksInTransitTitle')
  }
  return t('order.haltedTitle')
}

export const getETATruckTimeAndTitle = (
  estimatedTimeOfArrival: string,
  isFirstTruckLoading = false,
  t: Ti18n
): TruckTimeAndTitle => {
  let orderCardTruckTitle
  let orderCardTruckTime
  let orderCardTruckTimeAbbreviation
  let orderCardTruckIsMinutes = false

  const activeOrderNextTruckETAMoment = moment(estimatedTimeOfArrival)
  const orderRequestTimeMoment = moment().toISOString()
  const nextTruckDiffMinutes: number = activeOrderNextTruckETAMoment.diff(
    orderRequestTimeMoment,
    'minutes'
  )

  if (isTruckArrivingInLessThanOneHour(activeOrderNextTruckETAMoment)) {
    if (nextTruckDiffMinutes < 4) {
      orderCardTruckTitle = isFirstTruckLoading
        ? t('order.firstTruckArrivingSoonETA')
        : t('order.nextTruckArrivingSoonETA')
    } else {
      orderCardTruckTitle = isFirstTruckLoading
        ? t('order.firstTruckArrivingIn')
        : t('order.nextTruckArrivingIn')
      orderCardTruckTime = nextTruckDiffMinutes.toString()
      orderCardTruckTimeAbbreviation = t('order.minute')
      orderCardTruckIsMinutes = true
    }

    return {
      orderCardTruckTitle,
      orderCardTruckTime,
      orderCardTruckTimeAbbreviation,
      orderCardTruckIsMinutes
    }
  }
  const truckTimeObject = estimatedTimeOfArrival ? getTruckTimeObject(estimatedTimeOfArrival) : null
  return {
    orderCardTruckTitle: isFirstTruckLoading
      ? t('order.firstTruckArrivingAt')
      : t('order.nextTruckArrivingAt'),
    ...truckTimeObject
  }
}

/* eslint-disable max-params */
const getTruckInformation = (
  order: Order,
  isFirstTruckLoading: boolean,
  isFirstTruckInTransit: boolean,
  isHalted: boolean,
  t: Ti18n
): TruckInformation => {
  /* eslint-enable max-params */
  const activeOrderNextTruckETA = getEstimatedTimeOfArrival(order)

  // Order is onHold
  // Order not yet fully delivered
  // No trucks in transit
  if (isHalted && getInTransitQuantity(order) === 0) {
    return {
      order,
      orderCardTruckTitle: t('order.orderNotUpcomingTrucks')
    }
  }

  if (getOnSiteQuantity(order) === 0 && getPouredQuantity(order) === 0) {
    if (getInTransitQuantity(order) === 0) {
      // first truck Loading
      return {
        order,
        orderCardTruckTitle: t('order.firstTruckLoading')
      }
    }
    if (activeOrderNextTruckETA) {
      // first truck arriving in from ETA
      return {
        order,
        ...getETATruckTimeAndTitle(activeOrderNextTruckETA, true, t)
      }
    }
    // no eta available
    return {
      order,
      orderCardTruckTitle: t('order.notAvailableETA')
    }
  }
  if (getOnSiteQuantity(order) + getPouredQuantity(order) > 0) {
    if (getInTransitQuantity(order) > 0) {
      if (activeOrderNextTruckETA) {
        return {
          order,
          ...getETATruckTimeAndTitle(activeOrderNextTruckETA, false, t)
        }
      }
      return {
        order,
        orderCardTruckTitle: t('order.notAvailableETA')
      }
    }
    // no trucks in transit
    return {
      order,
      orderCardTruckTitle: t('shipmentDetails.trucksInTransit', {
        count: 0
      })
    }
  }
  return {
    order,
    orderCardTruckTitle: t('order.notAvailableETA')
  }
}

// In case of unconfirmed Order
// First truck scheduled for
const getTruckInformationForNonActive: any = (order: Order, t: Ti18n): TruckInformation => {
  const {shippingDate, schedule} = order
  const {scheduleStartDate} = schedule
  const orderDate = scheduleStartDate || shippingDate
  return {
    order,
    ...getTruckTimeObject(orderDate),
    orderCardTruckTitle: t('order.scheduledStartTimeSimple')
  }
}

const isFirstTruckLoading = (order: Order): boolean =>
  getPouredQuantity(order) === 0 &&
  getInTransitQuantity(order) === 0 &&
  getOnSiteQuantity(order) === 0

const isFirstTruckInTransit = (order: Order): boolean =>
  getPouredQuantity(order) === 0 &&
  getInTransitQuantity(order) > 0 &&
  getOnSiteQuantity(order) === 0

export const getOrderCardStatus = (order: Order, t: Ti18n): any => {
  const {orderStatus, isHalted = true, shippingAddress} = order
  const {street, city} = shippingAddress
  const orderType = getOrderType(orderStatus)
  const {
    OrderTypeActive,
    OrderTypeConfirmed,
    OrderTypeReserved,
    OrderTypePending,
    OrderTypeCompleted,
    OrderTypePaymentPending
  } = OrderTypes

  const defaultOrderCardProperties = {
    orderCardStyles: orderCardDefaultStyles,
    orderCardAddress: `${street}\n${trim(city)}`,
    orderCardTimeIndicator: 'orderCardTimeIndicator',
    orderCardSpacingFlowRateIndicator: 'orderCardTimeIndicator',
    orderCardRMCTestBooked: order.isTestBooked
      ? React.createElement(OrderCardRMCTestBooked, null)
      : null,
    orderCardProgressDetails: 'orderCardTimeIndicators'
  }

  const defaultOrderCardUnconfirmedProperties = {
    ...defaultOrderCardProperties,
    orderCardStyles: {
      ...defaultOrderCardProperties.orderCardStyles,
      circleColor: Colors.Yellow,
      labelColor: Colors.Yellow
    },
    orderCardTitle: t('order.unconfirmedTitle'),
    orderCardQuantityInformation: React.createElement(OrderCardQuantityInformation, order, null),
    orderCardMix: React.createElement(OrderCardMix, {order}, null),
    orderCardTruckInformationComponent: React.createElement(
      OrderCardTruckInformation,
      getTruckInformationForNonActive(order, t),
      null
    )
  }

  switch (orderType) {
    case OrderTypeActive:
      return {
        ...defaultOrderCardProperties,
        orderCardTitle: isHalted ? getHaltedTitle(order, t) : t('order.activeTitle'),
        orderCardStyles: {
          ...defaultOrderCardProperties.orderCardStyles,
          circleColor: isHalted ? Colors.Yellow : Colors.Azure,
          labelColor: isHalted ? Colors.Yellow : Colors.Azure
        },
        orderCardProgressInformationComponent: React.createElement(
          OrderCardProgressInformation,
          {order, isCompleted: false},
          null
        ),
        orderCardMix: React.createElement(OrderCardMix, {order}, null),
        orderCardTruckInformationComponent: React.createElement(
          OrderCardTruckInformation,
          {
            ...getTruckInformation(
              order,
              isFirstTruckLoading(order),
              isFirstTruckInTransit(order),
              isHalted,
              t
            )
          },
          null
        )
      }

    case OrderTypeCompleted:
      return {
        ...defaultOrderCardProperties,
        orderCardTitle: isHalted ? t('order.haltedTitle') : t('order.completedTitle'),
        orderCardStyles: {
          ...defaultOrderCardProperties.orderCardStyles,
          circleColor: isHalted ? Colors.Yellow : Colors.Jewel,
          labelColor: isHalted ? Colors.Yellow : Colors.Jewel
        },
        orderCardMix: React.createElement(OrderCardMix, {order}, null),
        orderCardProgressInformationComponent: React.createElement(
          OrderCardProgressInformation,
          {order, isCompleted: true},
          null
        )
      }

    case OrderTypeConfirmed:
      return {
        ...defaultOrderCardProperties,
        orderCardStyles: {
          ...defaultOrderCardProperties.orderCardStyles,
          circleColor: Colors.TealDark,
          labelColor: Colors.TealDark
        },
        orderCardTitle: t('order.confirmedTitle'),
        orderCardQuantityInformation: React.createElement(
          OrderCardQuantityInformation,
          order,
          null
        ),
        orderCardMix: React.createElement(OrderCardMix, {order}, null),
        orderCardTruckInformationComponent: React.createElement(
          OrderCardTruckInformation,
          getTruckInformationForNonActive(order, t),
          null
        )
      }

    case OrderTypeReserved:
    case OrderTypePending:
    case OrderTypePaymentPending:
      return {
        ...defaultOrderCardUnconfirmedProperties
      }

    default:
      return {
        ...defaultOrderCardProperties,
        orderCardMix: React.createElement(OrderCardMix, {order}, null)
      }
  }
}
