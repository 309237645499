import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {SchemaIDs} from '../schemas'

const {RoleRequestsSchemaID} = SchemaIDs

export const assignPermissions = (api: AxiosInstance) => (
  RoleRequestId: string,
  data: any = {},
  axiosConfig?: AxiosRequestConfig
) => api.post(`/roleRequests/Assign/${RoleRequestId}`, data, axiosConfig)

export const createRoleRequest = (api: AxiosInstance) => (
  data: any = {},
  axiosConfig?: AxiosRequestConfig
) => {
  const config = {params: {}, schemaId: RoleRequestsSchemaID, ...axiosConfig}
  return api.post('/roleRequests', data, config)
}
