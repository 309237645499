/**
 * Generated from Api Bluepring json shema
 * More: https://api.hce.heidelbergcement.com/API/hub#deliveries-show-details-of-deliveries-get
 */

import {Type} from './Invoice'

export interface Delivery {
  /**
   * Reference to the customer in the backend system
   */
  customerId: string
  deliveryEvents: DeliveryEvents
  /**
   * Unique Identifier for the delivery/ticket/shipment s
   */
  deliveryId: string
  /**
   * business identifier of the delivery that is normally also printed on delivery note
   */
  deliveryNumber?: null | string
  /**
   * Status information of the delivery
   */
  deliveryStatus: DeliveryStatus
  links?: Link[]
  /**
   * quanity loaded
   */
  loadQuantity?: number | null
  /**
   * unit of measurement
   */
  loadQuantityUom?: null | string
  /**
   * reference to the order
   */
  orderId?: null | string
  /**
   * reference to the order
   */
  orderNumber?: null | string
  /**
   * reference to the plant
   */
  plantId?: null | string
  /**
   * name of the plant
   */
  plantName?: null | string
  /**
   * reference to the order
   */
  truckId?: null | string
  /**
   * reference to the order
   */
  truckName?: null | string
}

export interface DeliveryEvents {
  arrived?: null | string
  loadFinished?: null | string
  loadStarted?: null | string
  unloadFinished?: null | string
  unloadStarted?: null | string
}

/**
 * Status information of the delivery
 */
export enum DeliveryStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InTransit = 'inTransit',
  Invoiced = 'invoiced',
  Loaded = 'loaded',
  Loading = 'loading',
  Open = 'open',
  Unknown = 'unknown',
  Unloaded = 'unloaded',
  Unloading = 'unloading',
  WaitingForLoad = 'waitingForLoad',
  WaitingForUnload = 'waitingForUnload'
}

export interface Link {
  /**
   * Link to the document
   */
  href: string
  /**
   * media type
   */
  media?: null | string
  /**
   * Type of the relationship to the reference
   */
  rel?: Rel
  /**
   * indicating the type of document content
   */
  type?: Type | null
}

/**
 * Type of the relationship to the reference
 */
export enum Rel {
  Documents = 'documents'
}
