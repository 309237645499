import {Branding} from './Branding.types'

export const BRANDING = 'BRANDING'
export const BRANDING_FETCH_REQUEST = 'BRANDING/FETCH_REQUEST'
export const BRANDING_FETCH_SUCCESS = 'BRANDING/FETCH_SUCCESS'
export const BRANDING_FETCH_FAILURE = 'BRANDING/FETCH_FAILURE'

interface FetchBrandingRequest {
  type: typeof BRANDING_FETCH_REQUEST
  params: any
}

interface FetchBrandingFailure {
  type: typeof BRANDING_FETCH_FAILURE
  error: boolean
}

interface FetchBrandingSuccess {
  type: typeof BRANDING_FETCH_SUCCESS
  data: Branding
}

export type BrandingActionTypes = FetchBrandingRequest | FetchBrandingFailure | FetchBrandingSuccess
