import {Moment} from 'moment'

export enum FocusedInput {
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export interface Dates {
  startDate: Moment | null
  endDate: Moment | null
}

export interface QuickSelectionItem {
  type: string
  title: string
  dates: Moment[]
}

export interface DateRange {
  from: Moment
  to: Moment
}
