// See more on https://api.hce.heidelbergcement.com/API/hub#invoices-details-of-an-invoice-get

export interface Invoice {
  /**
   * ISO 4217 currency code valid for entire invoice
   */
  invoiceCurrency: string
  /**
   * the posting dateof the invoice
   */
  invoiceDate?: null | string
  /**
   * Unique Identifier for the order
   */
  invoiceId: string
  /**
   * The invoice net amount in cents.
   */
  invoiceNetValue: number
  /**
   * Reference to Business value of the invoice number
   */
  invoiceNumber: string
  /**
   * The invoice tax amount in cents.
   */
  invoiceTaxValue: number
  lineItems: LineItem[]
  links: InvoiceLink[]
  /**
   * the payer of the invoice
   */
  payer: Payer
  /**
   * Status information of the invoice
   */
  status: Status
}

export interface LineItem {
  /**
   * Description of the material used by the customer
   */
  customerMaterialDescription?: null | string
  /**
   * Customer reference text used by customer to identify the order, e.g. customer purchase
   * order reference
   */
  customerReference?: null | string
  /**
   * Reference to the position number of the row in the invoice
   */
  itemNumber: string
  links: LineItemLink[]
  /**
   * Description of the material of the order
   */
  materialDescription: string
  /**
   * Reference to business key of the material in the backend system
   */
  materialNumber: string
  /**
   * The net amount in cents of the invoice item
   */
  netAmount: number
  /**
   * quantity billed
   */
  quantity: number
  /**
   * The unit of measurement of the quantity order
   */
  quantityUom: string
  /**
   * The tax amount in cents of the invoice item
   */
  taxAmount: number
  /**
   * the net unit price of the item
   */
  unitPrice: number
}

export interface LineItemLink {
  /**
   * Link to the endpoint
   */
  href: string
  /**
   * media type
   */
  media?: null | string
  /**
   * Type of the relationship to the reference
   */
  rel?: PurpleRel
}

/**
 * Type of the relationship to the reference
 */
export enum PurpleRel {
  Deliveries = 'deliveries',
  Orders = 'orders',
  Services = 'services'
}

export interface InvoiceLink {
  /**
   * Link to the document
   */
  href: string
  /**
   * media type
   */
  media?: null | string
  /**
   * Type of the relationship to the reference
   */
  rel?: FluffyRel
  /**
   * indicating the type of document content
   */
  type?: Type | null
}

/**
 * Type of the relationship to the reference
 */
export enum FluffyRel {
  Documents = 'documents'
}

export enum Type {
  DeliveryNote = 'deliveryNote',
  Invoice = 'invoice',
  ProofOfDelivery = 'proofOfDelivery'
}

/**
 * the payer of the invoice
 */
export interface Payer {
  /**
   * Unique Identifier for the customer accross backend systems
   */
  customerId: string
  /**
   * descriptive name of the customer, often equal to the company name
   */
  customerName: string
  /**
   * Reference to business related customer identifyer
   */
  customerNumber?: null | string

  payerId: string

  payerName: string

  payerNumber: string
}

/**
 * Status information of the invoice
 */
export enum Status {
  Cleared = 'cleared',
  Open = 'open'
}
